<template>
  <requests-table
    v-if="!isLoading"
    :requests="getUserRequests"
    :headers="headers"
  >
  </requests-table>
  <div class="center" v-else>
    <v-progress-circular
      indeterminate
      color="deep-orange lighten-2"
    ></v-progress-circular>
  </div>
</template>

<script>
import RequestsTable from "../../components/RequestsTable.vue";
import { mapGetters } from "vuex";
import { PaginationRequest } from '../../models';
import { REQUESTS_PAGE_SIZE } from "../../constants/index.js";

export default {
  title: 'Patient Requests',
  components: { RequestsTable },
  data() {
    return {
      isLoading: true,
      headers: [
        {
          text: "Request Date",
          align: "start",
          value: "request_date",
        },
        {
          text: "Requester Name",
          align: "start",
          value: "requester.fullName",
        },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Region", value: "address.geographicalHierarchy.name" },
        { text: "Request Type", value: "medicalService.nameEn" },
        { text: "Status", value: "status" },
        { text: "Added By Admin", value: "addedByAdmin" },
        { text: "Emergency", value: "emergency" },
        { text: "Comments", value: "comments" },
        { text: "Actions", value: "actions", sortable: false, align: "center", width: '195px' },
      ],
    };
  },
  computed: {
    ...mapGetters("requests", ["getUserRequests"]),
  },
  created() {
    this.loadUserRequests();
  },
  methods: {
    async loadUserRequests() {
      await this.$store.dispatch("requests/loadUserRequests", new PaginationRequest({ pageNumber: 1, pageSize: REQUESTS_PAGE_SIZE}));
      this.isLoading = false;
    },
  },
};
</script>

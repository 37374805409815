<template>
  <div>
    <v-expansion-panels v-model="panel" multiple class="my-4">
      <v-expansion-panel class="top-grey-border">
        <v-expansion-panel-header
          ><h2>Request Data</h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div v-if="userRequest != null">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="subheaders--text text-subtitle-1 text--darken-2"
                      >
                        Requester Details
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="text-subtitle-2">Full Name</div>
                      <span>
                        {{ getFullname }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <div class="text-subtitle-2">Email</div>
                      <span>
                        {{ userRequest.email }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <div class="text-subtitle-2">Phone Number</div>
                      <span>
                        {{ userRequest.phoneNumber }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-divider class="mx-4" horizontal></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="subheaders--text text-subtitle-1 text--darken-2"
                      >
                        Address Details
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="text-subtitle-2">Street Name</div>
                      <span>
                        {{ userRequest.address.streetName }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <div class="text-subtitle-2">Building Number</div>
                      <span>
                        {{ userRequest.address.flatNumber }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="text-subtitle-2">Flat Number</div>
                      <span>
                        {{ userRequest.address.flatNumber }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <div class="text-subtitle-2">Area</div>
                      <span>
                        {{ userRequest.address.geographicalHierarchy.name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-subtitle-2">Full Address</div>
                      <span>
                        {{ userRequest.address.fullAddress }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="d-none d-md-flex justify-center" md="1">
                  <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="subheaders--text text-subtitle-1 text--darken-2"
                      >
                        Request Details
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="8">
                      <div class="text-subtitle-2">Request Description</div>
                      <span>
                        {{ userRequest.description }}
                      </span>
                    </v-col>
                    <v-col cols="6" md="4">
                      <div class="text-subtitle-2">Status</div>
                      <span>
                        {{ userRequest.status }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="8">
                      <div class="text-subtitle-2">Medical Service</div>
                      <span>
                        {{ userRequest.medicalService.nameEn }}
                      </span>
                    </v-col>
                    <v-col cols="6" md="4">
                      <div class="text-subtitle-2">Medical Service Detail</div>
                      <span>
                        {{
                          userRequest.medicalServiceDetail
                            ? userRequest.medicalServiceDetail.nameEn
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-divider class="mx-4" horizontal></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="subheaders--text text-subtitle-1 text--darken-2"
                      >
                        Preferable Request Slot
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-for="slot in userRequest.timeSlots" :key="slot.id">
                    <v-col cols="6" md="4">
                      <div class="text-subtitle-2">Date</div>
                      <span>
                        {{ slot.slotDate }}
                      </span>
                    </v-col>
                    <v-col cols="6" md="4">
                      <div class="text-subtitle-2">Time from</div>
                      <span>
                        {{ slot.timeFrom }}
                      </span>
                    </v-col>
                    <v-col cols="6" md="4">
                      <div class="text-subtitle-2">Time to</div>
                      <span>
                        {{ slot.timeTo }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="top-grey-border">
      <v-card v-if="userRequest != null">
        <visits-table
          v-if="!isLoading"
          :visits="visits"
          :headers="headers"
          :userRequest="userRequest"
        >
        </visits-table>
        <div class="center" v-else>
          <v-progress-circular
            indeterminate
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import VisitsTable from "../../components/VisitsTable.vue";
import { mapGetters } from "vuex";

export default {
  title: 'Request Visits',
  components: { VisitsTable },
  props: ["id"],
  computed: {
    ...mapGetters("visits", ["visits"]),
    getFullname() {
      return (
        this.userRequest.firstName +
        " " +
        (this.userRequest.secondName ? this.userRequest.secondName  : '') +
        " " +
        this.userRequest.lastName
      );
    },
  },
  created() {
    this.loadUserRequestById();
    this.loadRquestVisits();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      headers: [
        {
          text: "Healthcare Provider",
          align: "start",
          value: "serviceProvider.name",
        },
        { text: "Individual", value: "worker.firstName" },
        { text: "Visit Date", value: "visitDate" },
        { text: "Fees", value: "fees" },
        { text: "Status", value: "status" },
        { text: "is Freelance", value: "isFreelance" },
        { text: "Comments", value: "comments" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      userRequest: null,
      panel: [0],
    };
  },
  methods: {
    async loadUserRequestById() {
      this.userRequest = await this.$store.dispatch(
        "requests/loadUserRequestById",
        this.id
      );
    },
    async loadRquestVisits() {
      await this.$store.dispatch("visits/loadRequestVisits", this.id);
    },
  },
};
</script>

import NetworkManager from "../../../network/network-manager.js";

export default {
  async loadUserRequests(context, payload) {
    return await NetworkManager.getPaginated(context, `user-request/mvp/paginated`, "UserRequest", "setPaginatedUserRequests", payload);
  },
  async loadUserRequestById(context, payload) {
    return await NetworkManager.get(context, `user-request/mvp/${payload}`, "UserRequest");
  },
  async addUserRequest(context, payload) {
    return await NetworkManager.post(context, `user-request/mvp`, payload, "UserRequest", "addUserRequest");
  },
  async createConfirmedUserRequest(context, payload) {
    return await NetworkManager.post(context, `user-request/mvp/confirmed`, payload, "UserRequest", "addUserRequest");
  },
  async updateUserRequest(context, payload) {
    const data = await NetworkManager.put(context, `user-request/mvp/${payload.userRequest.id}`, payload.userRequest, "UserRequest");
    context.commit("updateUserRequest", { userRequest: data, index: payload.index });
    return data;
  },
  async confirmUserRequest(context, payload) {
    const data = await NetworkManager.put(context, `user-request/mvp/${payload.userRequest.id}/confirm`, payload.userRequest, "UserRequest");
    context.commit("updateUserRequest", { userRequest: data, index: payload.index });
    return data;
  },
  async cancelUserRequest(context, payload) {
    const data = await NetworkManager.put(context, `user-request/mvp/${payload.userRequest.id}/cancel`, payload.userRequest, "UserRequest");
    context.commit("updateUserRequest", { userRequest: data, index: payload.index });
    return data;
  },
  async closeUserRequest(context, payload) {
    const data = await NetworkManager.put(context, `user-request/mvp/${payload.userRequest.id}/close`, payload.userRequest, "UserRequest");
    context.commit("updateUserRequest", { userRequest: data, index: payload.index });
    return data;
  },
};

import { MedicalUser, MedicalService, MedicalServiceDetail } from ".";
import TimeSlot from "./time-slot";
import Parser from "../network/parser.js";
import BaseModel from "./base-model";

export default class UserRequest extends BaseModel {
  constructor(rawData = {}) {
    super(rawData);
    this.id = rawData.id;
    this.requester = rawData.requester ? new MedicalUser(rawData.requester) : null;
    this.medicalService = rawData.medicalService ? new MedicalService(rawData.medicalService) : null;
    this.address = rawData.address;
    this.description = rawData.description;
    this.comments = rawData.comments;
    this.medicalServiceDetail = rawData.medicalServiceDetail ? new MedicalServiceDetail(rawData.medicalServiceDetail) : null;
    this.requestDate = rawData.requestDate;
    this.confirmedDate = rawData.confirmedDate;
    this.scheduledDate = rawData.scheduledDate;
    this.cancelledDate = rawData.cancelledDate;
    this.status = rawData.status;
    this.addedByAdmin = rawData.addedByAdmin;
    this.emergency = rawData.emergency;
    this.beneficiaries = rawData.beneficiaries ? Parser.fromJson(MedicalUser, rawData.beneficiaries) : [];
    this.timeSlots = rawData.timeSlots ? Parser.fromJson(TimeSlot, rawData.timeSlots) : [];
    this.medicalRecords = rawData.medicalRecords;
    this.firstName = rawData.firstName;
    this.secondName = rawData.secondName;
    this.lastName = rawData.lastName;
    this.email = rawData.email;
    this.phoneNumber = rawData.phoneNumber;
  }
}

import BaseModel from "./base-model";
import { GeographicalType } from ".";
export default class GeographicalHierarchy extends BaseModel {
    constructor (rawData = {}) {
        super(rawData);
        this.id = rawData.id;
        this.name = rawData.name;
        this.geographicalType = rawData.geographicalType ? new GeographicalType(rawData.geographicalType) : null;
        this.childOf = rawData.childOf;
    }

 
}
<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row><div class="text-subtitle-1">Request Details</div></v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userRequest.description"
                label="Request Description"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="userRequest.medicalService.nameEn"
                label="Medical Service"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="medicalServiceDetailName"
                label="Medical Service Detail"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row><div class="text-subtitle-1">Visit Details</div></v-row>
          <v-row>
            <v-col class="d-flex" cols="12">
              <v-checkbox
                v-model="visit.isFreelance"
                label="Freelance Visit"
                color="primary"
                hide-details
                v-on:change="changeVisitType"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!visit.isFreelance" class="d-flex" cols="6">
              <v-select
                :items="serviceProviders"
                item-text="name"
                item-value="id"
                v-model="visit.serviceProvider.id"
                v-on:change="loadWorkers"
                :rules="required"
                :disabled="visit.isFreelance"
                dense
              >
                <template v-slot:label>
                  Healthcare Provider
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col class="d-flex" cols="6">
              <v-select
                v-if="
                  visit.isFreelance ||
                    (visit.serviceProvider != null &&
                      visit.serviceProvider.id != null)
                "
                :items="workers"
                item-text="fullName"
                item-value="id"
                v-model="visit.worker.id"
                label="Individual"
                :disabled="
                  (visit.serviceProvider == null ||
                    visit.serviceProvider.id == null) &&
                    !visit.isFreelance
                "
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="visit.fees"
                :rules="[...required, ...numberRule]"
                maxlength="8"
                type="text"
                hint="Fees are in EGP"
              >
                <template v-slot:label>
                  Fees
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="visit.comments"
                label="Comments"
                maxlength="500"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dateDialog"
                v-model="showVisitDateDialog"
                :return-value.sync="visitDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="visitDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    :rules="required"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      Visit Date
                      <span class="mandatory-field">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="visitDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="red" @click="showVisitDateDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateDialog.save(visitDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="6">
              <v-dialog
                ref="timeDialog"
                v-model="showVisitTimeDialog"
                :return-value.sync="visitTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="visitTime"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :rules="required"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      Visit Time
                      <span class="mandatory-field">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="showVisitTimeDialog"
                  v-model="visitTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="red" @click="showVisitTimeDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.timeDialog.save(visitTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel" v-if="!isSaving">
        Cancel
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="save"
        :disabled="!valid"
        v-if="!isSaving"
      >
        Save
      </v-btn>
      <v-progress-circular
        v-else
        indeterminate
        color="deep-orange lighten-2"
      ></v-progress-circular>
    </v-card-actions>

    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { ServiceProvider, Worker } from "../models";
import DateFormatter from "../utilities/date-formatter";

export default {
  props: ["userRequest", "visit", "editedIndex"],
  data() {
    return {
      visitTime: null,
      showVisitTimeDialog: false,
      visitDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      showVisitDateDialog: false,
      valid: false,
      isSaving: false,
      required: [(v) => !!v || "This field is required"],
      numberRule: [(v) => !isNaN(parseInt(v)) || "This field must be a number"],
      showSnackbar: false,
      snackbarText: "",
    };
  },
  created() {
    this.loadServiceProviders();
    if (this.isNew) {
      this.loadFreelanceWorkers();
    } else {
      this.loadWorkers();
      this.visitTime = DateFormatter.getTime(this.visit.visitDate);
      this.visitDate = DateFormatter.getDate(this.visit.visitDate);
    }
  },
  computed: {
    ...mapGetters("serviceProvider", ["serviceProviders"]),
    ...mapGetters("workers", ["workers"]),
    formTitle() {
      return this.isNew ? "New Visit" : "Edit Visit";
    },
    isNew() {
      return this.editedIndex === -1;
    },
    medicalServiceDetailName() {
      return this.userRequest.medicalServiceDetail
        ? this.userRequest.medicalServiceDetail.name
        : "";
    },
  },
  methods: {
    async loadServiceProviders() {
      await this.$store.dispatch("serviceProvider/loadServiceProviders");
    },
    async loadServiceProviderWorkers() {
      this.visit.worker = new Worker();
      if (this.visit.serviceProvider.id != null) {
        await this.$store.dispatch(
          "workers/loadServiceProviderWorkers",
          this.visit.serviceProvider.id
        );
      }
    },
    async loadFreelanceWorkers() {
      await this.$store.dispatch("workers/loadFreelanceWorkers");
    },
    async loadWorkers() {
      if (this.visit.isFreelance) {
        this.loadFreelanceWorkers();
      } else {
        this.loadServiceProviderWorkers();
      }
    },
    async save() {
      let oldDate;
      try {
        this.isSaving = true;
        oldDate = this.isNew
          ? this.visitDate + " " + this.visitTime
          : this.visit.visitDate;
        this.visit.visitDate = this.visitDate + " " + this.visitTime;
        if (this.visit.worker.id === undefined) this.visit.worker = null;

        if (this.isNew) {
          await this.$store.dispatch("visits/scheduleVisit", this.visit);
        } else {
          await this.$store.dispatch(`visits/updateVisit`, {
            visit: this.visit,
            index: this.editedIndex,
          });
        }
        this.$emit("close", false);
      } catch (error) {
        this.visit.visitDate = oldDate;
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.isSaving = false;
      }
    },
    cancel() {
      this.$emit("close", true);
    },
    changeVisitType() {
      if (this.visit.isFreelance) {
        this.visit.worker = new Worker();
        this.visit.serviceProvider = null;
      } else {
        this.visit.serviceProvider = new ServiceProvider();
        this.visit.worker = null;
      }
      this.loadWorkers();
    },
  },
};
</script>

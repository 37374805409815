export default {
  getUserRequests(state) {
    return state.userRequests;
  },
  getTotalNumberOfRequests(state) {
    return state.totalNumberOfRequests;
  },
  hasUserRequests(state) {
    return state.userRequests && state.userRequests.length > 0;
  },
};

<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-form v-model="workerFormValid" ref="workerForm">
        <v-container>
          <v-row>
            <v-col cols="4" sm="4">
              <v-text-field v-model="editedWorker.firstName" :rules="required">
                <template v-slot:label>
                  First Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4">
              <v-text-field
                v-model="editedWorker.secondName"
                label="Second Name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4">
              <v-text-field v-model="editedWorker.lastName" :rules="required">
                <template v-slot:label>
                  Last Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-select
                :items="workerTypes"
                item-text="type"
                item-value="id"
                v-model="editedWorker.workerType.id"
                :rules="required"
                v-on:change="getMedicalServicesDetailsByWorkerType"
                dense
              >
                <template v-slot:label>
                  Type
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-select
                :items="medicalServicesDetails"
                item-text="nameEn"
                item-value="id"
                v-model="editedWorker.speciality.id"
                :rules="required"
                dense
              >
                <template v-slot:label>
                  Specialty
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" md="6">
              <v-text-field v-model="editedWorker.degree" :rules="required">
                <template v-slot:label>
                  Degree
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" md="6">
              <v-select
                :items="genders"
                v-model="editedWorker.gender"
                :rules="required"
              >
                <template v-slot:label>
                  Gender
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12">
              <v-checkbox
                v-model="editedWorker.freelancer"
                label="Freelancer"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedWorker.contractDate"
                    label="Contract Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedWorker.contractDate"
                  no-title
                  scrollable
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedWorker.birthdate"
                    label="Birthdate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedWorker.birthdate"
                  @input="menu2 = false"
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                type="number"
                v-model="editedWorker.phoneNumber"
                :rules="required"
              >
                <template v-slot:label>
                  Phone Number
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="editedWorker.email"
                label="Email"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel" v-if="!isAddWorker">
        Cancel
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="addWorker"
        v-if="!isAddWorker"
        :disabled="!workerFormValid"
      >
        Save
      </v-btn>
      <div class="center" v-else>
        <v-progress-circular
          indeterminate
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </div>
    </v-card-actions>
    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

 <script>
import { mapGetters } from "vuex";
import { MedicalServiceDetail } from "./../models";

export default {
  props: ["editedWorker", "editedIndex", "serviceProvider"],
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      workerFormValid: false,
      showSnackbar: false,
      snackbarText: "",
      genders: ["MALE", "FEMALE"],
      medicalServicesDetails: [],
      isAddWorker: false,
      required: [(v) => !!v || "this field is required"],
      emailRules: [
        (v) =>
          !!v == false ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          "E-mail must be valid",
      ],
    };
  },
  async created() {
    try {
      await this.loadWorkerTypes();
      await this.loadMedicalServicesDetails();
      if (!this.isNew) {
        this.getMedicalServicesDetailsByWorkerType();
      }
    } catch (error) {
      this.snackbarText = error;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapGetters("workers", ["workers"]),
    ...mapGetters("workers", ["workerTypes"]),
    formTitle() {
      return this.isNew ? "New Individual" : "Edit Individual";
    },
    isNew() {
      return this.editedIndex === -1;
    },
  },
  methods: {
    async loadWorkerTypes() {
      await this.$store.dispatch("workers/loadWorkerTypes");
    },
    async loadFreelanceWorkers() {
      await this.$store.dispatch("workers/loadFreelanceWorkers");
    },
    async loadMedicalServicesDetails() {
      await this.$store.dispatch("medicalServices/loadMedicalServicesDetails");
    },
    cancel() {
      this.$emit("close", true);
    },
    async addWorker() {
      try {
        this.isAddWorker = true;

        const chosenWorkerTypes = this.workerTypes.find(
          (workerType) => workerType.id === this.editedWorker.workerType.id
        );
        this.editedWorker.workerType = chosenWorkerTypes;

        const chosenSpeciality = this.medicalServicesDetails.find(
          (msDetail) => msDetail.id === this.editedWorker.speciality.id
        );
        this.editedWorker.speciality = chosenSpeciality;

        if (this.serviceProvider) {
          this.editedWorker.serviceProviders.push(this.serviceProvider);
        }
        if (this.isNew) {
          await this.$store.dispatch("workers/addWorker", this.editedWorker);
        } else {
          await this.$store.dispatch("workers/updateWorker", {
            worker: this.editedWorker,
            index: this.editedIndex,
          });
        }

        this.$emit("close", false);
        this.snackbarText = "Worker Added Successfully";
        this.snackbar = true;
      } catch (error) {
        this.showWorkerSnackbar = true;
        this.workerSnackbarText = error;
      } finally {
        this.isAddWorker = false;
        this.medicalServicesDetails = [];
      }
    },
    getMedicalServicesDetailsByWorkerType() {
      if (this.isNew) {
        this.editedWorker.speciality = new MedicalServiceDetail();
      }

      const currWorkerType = this.workerTypes.find(
        (workerType) => workerType.id === this.editedWorker.workerType.id
      );
      this.medicalServicesDetails = this.$store.getters[
        "medicalServices/medicalServicesDetailsByServiceId"
      ](currWorkerType.medicalService.id);
    },
  },
};
</script>
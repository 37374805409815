<template>
  <v-card>
    <v-card-title> Healthcare Institutions </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table :headers="headers" :items="items" :search="search">
      <!--eslint-disable -->
      <template #item.name="{ item }">
        <router-link :to="/serviceproviders/ + item.id" class="link-color">
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="subheaders--text">{{
            title
          }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
            persistent
            @keydown.esc="closeDialog(true)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Healthcare Institution
              </v-btn>
            </template>
            <new-service-provider
              v-if="dialog"
              @close="closeDialog"
              :serviceProvider="serviceProvider"
              :editedIndex="editedIndex"
            ></new-service-provider>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="670px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to deactivate this service
                provider?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  v-if="!isDelete"
                  text
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  v-if="!isDelete"
                  color="primary darken-1"
                  text
                  @click="deleteItemConfirm"
                  >Deactivate</v-btn
                >
                <div class="center" v-else>
                  <v-progress-circular
                    indeterminate
                    color="deep-orange lighten-2"
                  ></v-progress-circular>
                </div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--eslint-disable -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Healthcare Institution</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="deleteItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Deactivate Healthcare Institution</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<style scoped>
.link-color {
  color: var(--v-secondary-base);
}
</style>

<script>
import NewServiceProvider from "./NewServiceProvider.vue";
import {
  Address,
  GeographicalHierarchy,
  ServiceProvider,
  ProviderType,
  ContactPerson,
} from "../models";

export default {
  components: { NewServiceProvider },
  props: ["headers", "items", "search", "title"],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    itemId: 0,
    isDelete: false,
    snackbar: false,
    snackbarText: ``,
    serviceProvider: null,
  }),
  created() {
    this.serviceProvider = this.createFreshServiceProviderInstance();
  },
  computed: {
    isNew() {
      return this.editedIndex === -1;
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.serviceProvider = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.serviceProvider = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.isDelete = true;
      const itemStatus = {
        id: this.serviceProvider.id,
        isActive: false,
      };

      await this.$store.dispatch(
        "serviceProvider/removeServiceProvider",
        itemStatus
      );
      this.items.splice(this.editedIndex, 1);
      this.isDelete = false;
      this.snackbarText = "Healthcare Institution is deactivated successfully";
      this.snackbar = true;
      this.closeDelete();
    },
    closeDialog: function (isCancelled) {
      this.dialog = false;
      this.serviceProvider = this.createFreshServiceProviderInstance();
      this.editedIndex = -1;
      if (!isCancelled) {
        this.snackbarText = "Operation is done successfully";
        this.snackbar = true;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.serviceProvider = this.createFreshServiceProviderInstance();
      this.editedIndex = -1;
    },
    createFreshServiceProviderInstance() {
      return new ServiceProvider({
        address: new Address({
          geographicalHierarchy: new GeographicalHierarchy(),
        }),
        providerType: new ProviderType(),
        contactPerson: new ContactPerson(),
      });
    },
  },
};
</script>

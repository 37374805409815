export default {
  username(state) {
    return state.user.username;
  },
  accessToken(state) {
    return state.user.accessToken;
  },
  idToken(state) {
    return state.user.idToken;
  },
  isAuthenticated(state) {
    return !!state.user.token;
  }
};

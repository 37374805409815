<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="workers"
      :search="search"
      sort-by="firstName"
    >
      <!--eslint-disable -->
      <template #item.freelancer="{ item }">
        {{ isFreelancer(item.freelancer) }}
      </template>
      <template #item.isActive="{ item }">
        {{ isActive(item.isActive) }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="subheaders--text"
            >Manage Individuals</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="showSaveDialog"
            persistent
            @keydown.esc="closeSaveDialog(true)"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Individual
              </v-btn>
            </template>
            <new-worker
              v-if="showSaveDialog"
              @close="closeSaveDialog"
              :editedWorker="worker"
              :editedIndex="editedIndex"
              :serviceProvider="serviceProvider"
            ></new-worker>
          </v-dialog>
          <v-dialog v-model="showDeactivateDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h6"
                >Are you sure you want to deactivate this worker?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDeactivateDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirmDeactivateWorker"
                  >Deactivate</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showDeleteDialog" max-width="530px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to remove this worker from this service
                provider?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="closeDeleteDialog"
                  v-if="!isDeletingWorker"
                  >Cancel</v-btn
                >
                <v-btn
                  v-if="!isDeletingWorker"
                  color="primary darken-1"
                  text
                  @click="confirmDeleteWorker()"
                  >Delete</v-btn
                >
                <v-progress-circular
                  v-else
                  indeterminate
                  color="deep-orange lighten-2"
                ></v-progress-circular>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showLinkDialog" max-width="530px" persistent>
            <v-card>
              <v-card-title class="text-h5"
                >Link/Unlink Healthcare provider</v-card-title
              >
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <div class="text-subtitle-2">Fullname</div>
                        <span>
                          {{ worker.fullName }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          :items="serviceProviders"
                          v-model="chosenServiceProviderIds"
                          item-text="name"
                          item-value="id"
                          label="Healthcare Provider"
                          dense
                          multiple
                          chips
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="closeLinkDialog"
                  v-if="!isLinkingWorker"
                  >Cancel</v-btn
                >
                <v-btn
                  v-if="!isLinkingWorker"
                  color="primary darken-1"
                  text
                  @click="linkWorker()"
                  >Link</v-btn
                >
                <v-progress-circular
                  v-else
                  indeterminate
                  color="deep-orange lighten-2"
                ></v-progress-circular>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--eslint-disable -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="editWorker(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Worker</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="openLinkWorkerDialog(item)"
              v-if="!serviceProvider"
              v-bind="attrs"
              v-on="on"
            >
              mdi-link
            </v-icon>
          </template>
          <span>Link/Unlink Healthcare Provider</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="deactivateWorker(item)"
              :disabled="!canDeactivated(item)"
              v-if="!serviceProvider"
              v-bind="attrs"
              v-on="on"
            >
              mdi-cancel
            </v-icon>
          </template>
          <span>Deactivate Worker</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="openDeleteDialog(item)"
              v-bind="attrs"
              v-on="on"
              v-if="serviceProvider"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remove Worker</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-snackbar v-model="showSnackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { Worker, MedicalServiceDetail, WorkerType } from "../models";
import NewWorker from "./NewWorker.vue";
import { mapGetters } from "vuex";

export default {
  components: { NewWorker },
  props: ["headers", "workers", "serviceProvider", "search"],
  data: () => ({
    showSaveDialog: false,
    showLinkDialog: false,
    showDeactivateDialog: false,
    showDeleteDialog: false,
    showSnackbar: false,
    snackbarText: "",
    editedIndex: -1,
    worker: null,
    isDeletingWorker: false,
    isLinkingWorker: false,
    chosenServiceProviderIds: [],
  }),
  async created() {
    this.worker = this.createFreshWorkerInstance();
    await this.loadServiceProviders();
  },
  computed: {
    ...mapGetters("serviceProvider", ["serviceProviders"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Individual" : "Edit Individual";
    },
  },
  methods: {
    async loadServiceProviders() {
      if (!this.serviceProvider) {
        await this.$store.dispatch("serviceProvider/loadServiceProviders");
      }
    },

    editWorker(item) {
      this.editedIndex = this.workers.indexOf(item);
      this.worker = item;
      this.showSaveDialog = true;
    },

    deactivateWorker(item) {
      this.editedIndex = this.workers.indexOf(item);
      this.worker = Object.assign({}, item);
      this.showDeactivateDialog = true;
    },

    async confirmDeactivateWorker() {
      try {
        await this.$store.dispatch("workers/deactivateWorker", {
          activeStatus: { id: this.worker.id, isActive: false },
          index: this.editedIndex,
        });

        this.snackbarText = "Worker is deactivated successfully";
        this.showSnackbar = true;
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeDeactivateDialog();
      }
    },

    openDeleteDialog(item) {
      this.editedIndex = this.workers.indexOf(item);
      this.worker = Object.assign({}, item);
      this.showDeleteDialog = true;
    },

    openLinkWorkerDialog(item) {
      this.editedIndex = this.workers.indexOf(item);
      this.worker = Object.assign({}, item);
      this.chosenServiceProviderIds = this.worker.serviceProviders.map(
        (serviceProvider) => serviceProvider.id
      );
      this.showLinkDialog = true;
    },

    confirmDeleteWorker() {
      this.deleteWorker();
      this.closeDeleteDialog();
    },

    closeDeleteDialog() {
      this.showDeleteDialog = false;
      this.worker = this.createFreshWorkerInstance();
      this.editedIndex = -1;
    },

    closeLinkDialog() {
      this.showLinkDialog = false;
      this.worker = this.createFreshWorkerInstance();
      this.chosenServiceProviderIds = [];
      this.editedIndex = -1;
    },

    async deleteWorker() {
      try {
        this.isDeletingWorker = true;
        let deletedWorker = {
          id: this.worker.id,
          serviceProviderId: this.serviceProvider.id,
        };
        await this.$store.dispatch(
          "workers/unlinkWorkerFromServiceProvider",
          deletedWorker
        );
        await this.$store.dispatch(
          "workers/loadServiceProviderWorkers",
          this.serviceProvider.id
        );
        this.snackbarText = "Worker Deleted Successfully";
        this.showSnackbar = true;
      } catch (error) {
        this.snackbarText = error;
        this.showSnackbar = true;
      } finally {
        this.isDeletingWorker = false;
      }
    },

    async linkWorker() {
      try {
        this.isLinkingWorker = true;
        let payload = {
          worker: this.worker,
          serviceProviderIdList: { ids: this.chosenServiceProviderIds },
          index: this.editedIndex,
        };
        await this.$store.dispatch(
          "workers/updateWorkerServiceProviders",
          payload
        );
        this.snackbarText = "Healthcare Providers are updated successfully";
        this.showSnackbar = true;
        this.closeLinkDialog();
      } catch (error) {
        this.snackbarText = error;
        this.showSnackbar = true;
      } finally {
        this.isLinkingWorker = false;
      }
    },

    closeSaveDialog: function (isCancelled) {
      this.showSaveDialog = false;
      this.editedIndex = -1;
      this.worker = this.createFreshWorkerInstance();
      if (!isCancelled) {
        this.showSnackbar = true;
        this.snackbarText = "Operation Is Done Successfully";
        this.editedIndex = -1;
      }
    },

    closeDeactivateDialog() {
      this.showDeactivateDialog = false;
      this.worker = this.createFreshWorkerInstance();
      this.editedIndex = -1;
    },

    createFreshWorkerInstance() {
      return new Worker({
        speciality: new MedicalServiceDetail(),
        workerType: new WorkerType(),
      });
    },
    canDeactivated(item) {
      return item.isActive;
    },
    isFreelancer(freelancer) {
      return freelancer ? "Yes" : "No";
    },
    isActive(active) {
      return active ? "Yes" : "No";
    },
  },
};
</script>

<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" class="main-background">
      <nav-bar></nav-bar>
    </v-navigation-drawer>

    <v-app-bar app flat color="transparent">
      <v-app-bar-nav-icon
        color="headerText"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item key="logout" @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="main-background">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<style lang="scss">
.main-background {
  background: linear-gradient($gradient_color1, $gradient_color2);
}
</style>

<script>
import NavBar from "./components/NavBar.vue";
import updateToken from "@/middlewares/update-token";
import Vue from "vue";

export default Vue.extend({
  components: { NavBar },
  name: "App",

  data: () => ({
    drawer: null,
  }),
  watch: {
    $route() {
      updateToken();
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
  },
});
</script>


<style>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
</style>
export default {
    visits(state) {
      return state.visits;
    },
    getTotalNumberOfVisits(state) {
      return state.totalNumberOfVisits;
    },
    hasVisits(state) {
      return state.visits && state.visits.length > 0;
    },
  };
<template>
  <visits-table v-if="!isLoading" :visits="visits" :headers="headers">
  </visits-table>
  <div class="center" v-else>
    <v-progress-circular
      indeterminate
      color="deep-orange lighten-2"
    ></v-progress-circular>
  </div>
</template>

<script>
import VisitsTable from "../../components/VisitsTable.vue";
import { mapGetters } from "vuex";
import { VISITS_PAGE_SIZE } from "../../constants/index.js";
import { PaginationRequest } from '../../models';

export default {
  title: 'Visits',
  components: { VisitsTable },
  data() {
    return {
      isLoading: true,
      headers: [
        {
          text: "Healthcare Provider",
          align: "start",
          value: "serviceProvider.name",
        },
        { text: "Individual", value: "worker.firstName" },
        { text: "Visit Date", value: "visitDate" },
        { text: "Fees", value: "fees" },
        { text: "Status", value: "status" },
        { text: "is Freelance", value: "isFreelance" },
        { text: "Comments", value: "comments" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("visits", ["visits"]),
  },
  created() {
    this.loadUserRequests();
  },
  methods: {
    async loadUserRequests() {
      await this.$store.dispatch("visits/loadPaginatedVisits", new PaginationRequest({ pageNumber: 1, pageSize: VISITS_PAGE_SIZE}));
      this.isLoading = false;
    },
  },
};
</script>

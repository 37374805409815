import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueLogger from "vuejs-logger";
import "@/plugins/keycloak";
import axios from "axios";
import updateToken from "@/middlewares/update-token";
import { APIS_URL } from "./constants";
import interceptRequests from "./interceptor/axios-interceptor";
import titleMixin from './mixins/titleMixin'

Vue.mixin(titleMixin)

Vue.config.productionTip = false;

Vue.use(VueLogger);

const backendApi = axios.create({
  baseURL: APIS_URL
});

interceptRequests(backendApi);

Vue.prototype.$http = backendApi; 

Vue.$keycloak
  .init({ onLoad: 'login-required' })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        vuetify,
        router,
        el: "#app",
        store,
        render: (h) => h(App, { props: { keycloak: Vue.$keycloak } }),
      });

      if (Vue.$keycloak.token && Vue.$keycloak.idToken && Vue.$keycloak.token != '' && Vue.$keycloak.idToken != '') {

        let payload = {
          username: Vue.$keycloak.subject,
          idToken: Vue.$keycloak.idToken,
          accessToken: Vue.$keycloak.token
        }
        store.dispatch("auth/login", payload);
      }
    }

    window.onfocus = () => {
      updateToken();
    };
  })
  .catch(() => {
    Vue.$log.error("Authenticated Failed");
  });


export default {
  setUserRequests(state, payload) {
    state.userRequests = payload;
  },
  setPaginatedUserRequests(state, payload) {
    state.userRequests = payload.results;
    state.totalNumberOfRequests = payload.totalNumberOfElements;
  },
  addUserRequest(state, payload) {
    state.userRequests.unshift(payload);
  },
  updateUserRequest(state, payload) {
    state.userRequests.splice(payload.index, 1, payload.userRequest);
  }
};

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 223 34"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M0 0L24.8181 0L24.8181 32.5484L0 32.5484L0 0Z"
        transform="translate(0.0995804 0.27922773)"
        id="path_1"
      />
      <path
        d="M0 0L24.818 0L24.818 32.5484L0 32.5484L0 0Z"
        transform="translate(0.041981217 0.27922773)"
        id="path_2"
      />
      <clipPath id="mask_1">
        <use xlink:href="#path_1" />
      </clipPath>
      <clipPath id="mask_2">
        <use xlink:href="#path_2" />
      </clipPath>
    </defs>
    <g id="Group-23">
      <path
        d="M21.5926 24.7285C15.2403 27.6109 8.39 23.169 6.07759 17.1991C4.41108 12.8966 4.13085 7.32816 6.79318 3.34776C7.39855 2.44311 8.44803 2.03466 9.05223 1.99997C10.451 1.91979 11.3317 2.15623 12.7022 2.46702C14.0719 2.77838 15.3873 3.30724 16.6482 4.05445C17.9091 4.80138 19.1 5.80515 20.2209 7.06577C20.3311 7.18968 20.451 7.33079 20.5773 7.48443C21.5407 8.65525 23.4428 7.97043 23.4428 6.45412L23.4428 3.58332C23.4428 1.60435 21.8381 0 19.8587 0L8.95863 0C8.21154 0 6.84859 0.366175 5.88426 1.09707C4.91876 1.82912 3.99992 2.83261 3.1289 4.10868C2.25672 5.38534 1.51779 6.87948 0.910676 8.59053C0.30385 10.303 0 12.124 0 14.0534C0 16.8863 0.45111 19.3991 1.3545 21.5938C2.25672 23.788 3.43246 25.6322 4.87998 27.1261C6.32778 28.6202 7.96222 29.7488 9.78357 30.5112C11.6049 31.2738 13.4496 31.6552 15.3173 31.6552C18.15 31.6552 20.4382 30.9082 22.1819 29.4141C23.9254 27.92 24.8746 25.6789 25.0306 22.6909C24.6702 22.9469 23.6513 23.5868 23.3746 23.7713C22.813 24.146 22.1394 24.4804 21.5926 24.7285"
        transform="translate(0 1.7472159)"
        id="Fill-1"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <path
        d="M21.6684 13.8664C21.2322 15.0186 20.7266 16.2008 20.1506 17.415C19.5741 18.629 18.9364 19.8042 18.236 20.94C17.5355 22.0764 16.7724 23.0956 15.9478 23.9979C15.1225 24.9011 14.2506 25.625 13.333 26.169C12.4141 26.7139 11.4568 26.9862 10.4607 26.9862C9.27706 26.9862 8.38971 26.5119 7.79892 25.562C7.20726 24.6131 6.91157 23.5157 6.91157 22.2706C6.91157 20.4963 7.26908 19.0182 7.98555 17.8351C8.70143 16.6526 9.73691 15.6334 11.0911 14.7772C12.4453 13.9212 14.1031 13.1822 16.0647 12.5594C18.0257 11.937 20.2361 11.33 22.6957 10.7385C22.4461 11.6723 22.1041 12.7154 21.6684 13.8664M15.3173 0L5.37395 0C3.90194 0 2.7087 1.19297 2.7087 2.66466L2.7087 3.83605C2.7087 5.64738 4.71376 6.7421 6.23651 5.7605C7.78259 4.76402 9.41294 3.74364 10.1571 3.37717C13.9121 1.52941 17.2463 2.39936 18.4929 2.98068C19.4155 3.4107 20.1766 3.92002 20.8044 4.52117C21.5428 5.22815 22.1519 6.37418 22.4951 7.26017C22.7319 7.87094 22.4712 8.55926 21.8898 8.86071C21.294 9.16915 20.5586 9.42163 19.6838 9.61812C18.2981 9.92949 16.757 10.2484 15.0604 10.575C13.3633 10.9018 11.6283 11.2988 9.85355 11.7656C8.07886 12.2326 6.45988 12.9175 4.99691 13.8198C3.53335 14.7229 2.33486 15.8976 1.40115 17.345C0.467148 18.7922 0 20.6525 0 22.9242C0 24.1694 0.186917 25.3285 0.560461 26.4025C0.934296 27.4763 1.46297 28.4028 2.14824 29.1803C2.83263 29.9587 3.65787 30.5657 4.62336 31.0013C5.5877 31.4368 6.678 31.6552 7.89223 31.6552C9.82206 31.6552 11.5113 31.2966 12.9591 30.5811C14.4066 29.8654 15.7063 28.7996 16.8581 27.383C18.01 25.967 19.0685 24.216 20.034 22.1303C20.8858 20.2893 21.7262 18.1925 22.5546 15.841C22.6001 15.7113 22.7949 15.7538 22.7794 15.8906C22.6992 16.6054 22.4572 19.4179 21.6215 21.9671C21.1815 23.3099 20.6717 24.753 19.9873 26.2623C19.3021 27.7725 17.3253 31.6552 17.3253 31.6552L25.0306 31.6552L25.0306 9.898C25.0306 6.75435 24.1436 4.31884 22.3689 2.59148C20.5942 0.863826 18.2433 0 15.3173 0"
        transform="translate(28.443878 1.7472159)"
        id="Fill-3"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <path
        d="M20.7955 0.177332C20.7894 0.17675 20.7833 0.175877 20.7775 0.175004C20.1725 0.0978881 19.4664 0.0586026 18.66 0.0586026C17.2017 0.0586026 15.8133 0.299262 14.4953 0.780873C13.1763 1.26277 11.9664 1.92248 10.8657 2.76144C9.76414 3.60011 8.78694 4.60233 7.93411 5.76722C7.29514 6.63935 6.75671 7.56765 6.31794 8.5527C6.22467 8.76222 5.90968 8.69151 5.90968 8.46191L5.90968 3.41911C5.90968 1.52031 4.365 -0.0150213 2.46871 0.000110903L0 0.0193171C0 0.0193171 1.74024 5.06823 2.26822 7.72451C2.79532 10.3808 3.3076 13.1698 3.3076 16.09C3.3076 19.0105 3.04347 21.7986 2.51637 24.4552C1.98839 27.1114 0.99289 31.6552 0.99289 31.6552L8.73261 31.6552C8.73261 31.6552 7.73623 27.1114 7.20884 24.4552C6.68116 21.7986 6.4179 19.0105 6.4179 16.09C6.4179 13.8338 6.94471 11.3114 8.32144 9.40389C9.71591 7.47104 11.3129 6.11497 13.6729 5.74452C14.6638 5.58912 15.8049 5.55071 16.7731 5.83997C17.8816 6.17142 18.8344 6.86197 19.87 7.35551C19.8834 7.36192 19.8967 7.36832 19.9101 7.37501C21.2188 8.02482 22.7551 7.1003 22.7551 5.63743L22.7551 2.43086C22.7551 1.29537 21.92 0.323416 20.7955 0.177332"
        transform="translate(55.75 1.7472159)"
        id="Fill-5"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <path
        d="M0 0C3.34926 6.63186 3.34926 14.6284 0 22.2759L7.96429 22.2759C5.03854 14.6284 5.24284 6.47204 7.96429 0L0 0Z"
        transform="translate(136.53061 11.126526)"
        id="Fill-7"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <path
        d="M3.42806 0C2.95503 0 2.51188 0.0918463 2.0983 0.275244C1.68445 0.458643 1.31951 0.713869 1.00464 1.04004C0.68948 1.36651 0.44344 1.7386 0.26595 2.15633C0.0887449 2.57464 0 3.02798 0 3.51724C0 4.49605 0.334784 5.32708 1.00464 6.01004C1.67421 6.6933 2.4823 7.03448 3.42806 7.03448C3.90108 7.03448 4.33911 6.94293 4.74302 6.75924C5.14664 6.57584 5.50645 6.32621 5.82189 6.01004C6.13648 5.69417 6.38337 5.32179 6.56058 4.89376C6.73807 4.46544 6.82653 4.0065 6.82653 3.51724C6.82653 2.53873 6.49658 1.70799 5.83668 1.02444C5.17622 0.341774 4.37382 0 3.42806 0"
        transform="translate(137.66837 0.5748021)"
        id="Fill-9"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <path
        d="M20.439 2.05447L20.4282 2.02939C19.8869 0.78307 18.6644 0 17.3392 0L12.3269 0L12.3269 0.14023C13.4444 1.07403 14.4569 2.14776 15.3631 3.36172C16.1181 4.42 16.8057 5.67274 17.4252 7.11994C18.0444 8.56742 18.3542 10.2097 18.3542 12.0458C18.3542 14.0066 18.0444 15.7576 17.4252 17.2984C16.8057 18.8392 16.0125 20.131 15.0458 21.1732C14.0786 22.2166 13.0066 23.0175 11.8286 23.5778C10.6502 24.1382 9.48656 24.418 8.33877 24.418C7.49264 24.418 6.6915 24.2629 5.93678 23.9513C5.18149 23.6405 4.51618 23.1656 3.94285 22.5274C3.36867 21.8898 2.90769 21.0881 2.56075 20.1228C2.21296 19.1584 2.03948 18.0065 2.03948 16.6678C2.03948 15.9521 2.1074 14.9719 2.24352 13.7268C2.37935 12.4822 2.66602 11.1047 3.10436 9.5948C3.54214 8.08551 4.1616 6.50596 4.96245 4.85556C5.76274 3.20633 6.82762 1.63436 8.15766 0.14023L8.15766 0L0 0L0 14.1932L0 20.2864C0 22.3566 0.158473 24.2315 0.475984 25.9122C0.793212 27.5932 1.35975 28.9704 2.17532 30.0441C2.99117 31.1182 4.19896 31.6552 5.80094 31.6552C6.9793 31.6552 8.18737 31.3047 9.42628 30.6045C10.6649 29.9042 11.8736 28.9704 13.0519 27.8031C14.2303 26.6358 15.3404 25.29 16.3829 23.7647C17.4252 22.24 18.3316 20.6525 19.1022 19.0024C19.8725 17.3529 20.4843 15.6955 20.9376 14.03C21.3907 12.3653 21.6173 10.8012 21.6173 9.33766C21.6173 7.59485 21.4962 6.1325 21.2548 4.94915C21.0126 3.76638 20.7407 2.80168 20.439 2.05447"
        transform="translate(149.04591 1.7472159)"
        id="Fill-11"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <g id="Group-15" transform="translate(78.505104 0.5748021)">
        <path
          d="M0 0L24.8181 0L24.8181 32.5484L0 32.5484L0 0Z"
          transform="translate(0.0995804 0.27922773)"
          id="Clip-14"
          fill="none"
          fill-rule="evenodd"
          stroke="none"
        />
        <g clip-path="url(#mask_1)">
          <path
            d="M21.0993 9.68433C20.4839 11.5118 19.5649 13.1201 18.4483 14.5537C17.3346 15.9872 16.1403 17.1696 14.8681 18.1034C13.5958 19.037 12.3158 19.6718 11.0283 20.013C9.74056 20.3515 8.33453 20.4831 6.63791 19.5634C4.79457 18.5639 4.95252 16.023 5.01754 15.3658C5.21951 13.3297 5.86685 10.4482 7.5229 7.84862C10.5076 3.16365 14.6296 2.67065 15.6055 2.55077C22.1672 1.74474 22.009 6.9825 21.0993 9.68433M20.5929 0.911153C17.0599 -0.957088 8.90361 -0.0924329 4.25281 4.81324C-0.5476 9.87162 -1.94298 20.8055 3.53958 27.5537C4.72724 29.0154 6.01474 30.1884 7.64863 31.0026C9.48104 31.9162 11.1791 32.5484 13.6692 32.5484C19.9663 32.5484 23.1383 29.8539 23.5698 29.2897L23.5698 21.6353C23.5698 21.6353 21.3038 25.5814 19.0284 27.3078C17.4687 28.491 14.7841 29.6646 11.7502 28.9568C6.23829 27.6709 5.47471 22.9106 5.20772 21.5023C5.17434 21.3267 5.34898 21.1883 5.50693 21.2663C6.01359 21.5166 7.41386 22.1239 8.39725 22.3739C9.33029 22.6131 10.4106 22.796 11.6708 22.796C13.6666 22.796 15.4081 22.4683 16.9002 21.81C18.3896 21.1541 19.8892 20.2237 20.9048 19.0191C22.7035 16.8838 23.9787 14.3013 24.4925 11.5282C24.939 9.11864 25.1114 5.80657 23.7689 3.6461C22.9607 2.34707 21.9077 1.60757 20.5929 0.911153"
            transform="translate(0.0995804 0.27922773)"
            id="Fill-13"
            fill="#EF5430"
            fill-rule="evenodd"
            stroke="none"
          />
        </g>
      </g>
      <g id="Group-18" transform="translate(172.93878 0.5748021)">
        <path
          d="M0 0L24.818 0L24.818 32.5484L0 32.5484L0 0Z"
          transform="translate(0.041981217 0.27922773)"
          id="Clip-17"
          fill="none"
          fill-rule="evenodd"
          stroke="none"
        />
        <g clip-path="url(#mask_2)">
          <path
            d="M21.0993 9.68433C20.4839 11.5118 19.5649 13.1201 18.4483 14.5537C17.3346 15.9872 16.1403 17.1696 14.8681 18.1034C13.5958 19.037 12.3158 19.6718 11.0281 20.013C9.74027 20.3515 8.33424 20.4831 6.63762 19.5634C4.79457 18.5639 4.95252 16.023 5.01754 15.3658C5.21922 13.3297 5.86685 10.4482 7.5229 7.84862C10.5076 3.16365 14.6296 2.67065 15.6055 2.55077C22.1672 1.74474 22.009 6.9825 21.0993 9.68433M20.5929 0.911153C17.0599 -0.957088 8.90361 -0.0924329 4.25281 4.81324C-0.5476 9.87162 -1.94298 20.8055 3.53958 27.5537C4.72724 29.0154 6.01474 30.1884 7.64863 31.0026C9.48104 31.9162 11.1791 32.5484 13.6692 32.5484C19.9663 32.5484 23.1383 29.8539 23.5698 29.2897L23.5698 21.6353C23.5698 21.6353 21.3038 25.5814 19.0284 27.3078C17.4687 28.491 14.7841 29.6646 11.7499 28.9568C6.23829 27.6709 5.47471 22.9106 5.20743 21.5023C5.17405 21.3267 5.34898 21.1883 5.50693 21.2663C6.01359 21.5166 7.41386 22.1239 8.39725 22.3739C9.33029 22.6131 10.4106 22.796 11.6705 22.796C13.6666 22.796 15.4081 22.4683 16.9002 21.81C18.3896 21.1541 19.8892 20.2237 20.9048 19.0191C22.7035 16.8838 23.9784 14.3013 24.4925 11.5282C24.939 9.11864 25.1111 5.80657 23.7689 3.6461C22.9605 2.34707 21.9077 1.60757 20.5929 0.911153"
            transform="translate(0.041981217 0.27922773)"
            id="Fill-16"
            fill="#EF5430"
            fill-rule="evenodd"
            stroke="none"
          />
        </g>
      </g>
      <path
        d="M19.6135 26.2463C13.2351 29.1039 8.40967 23.1688 6.09162 17.199C4.42126 12.8965 4.14039 7.32809 6.80887 3.34802C7.41563 2.44309 8.46754 2.03464 9.07313 1.99995C10.4749 1.92007 11.3581 2.15621 12.7316 2.46699C14.1044 2.77865 15.4228 3.3075 16.6866 4.05442C17.9504 4.80134 19.1441 5.8051 20.2676 7.06571C20.3781 7.1899 20.4982 7.33101 20.6248 7.48465C21.5905 8.65547 23.497 7.97035 23.497 6.45436L23.497 3.58329C23.497 1.60433 21.8886 0 19.9046 0L8.9796 0C8.2305 0 6.8644 0.366171 5.89784 1.09735C4.93012 1.82911 4.00945 2.83258 3.13642 4.10864C2.26222 5.38529 1.5213 6.87942 0.913071 8.59074C0.304552 10.3029 0 12.1239 0 14.0536C0 16.8862 0.452151 19.3992 1.35762 21.5936C2.26222 23.788 3.44038 25.632 4.89124 27.1261C6.3424 28.6203 7.9806 29.7488 9.80616 30.5109C11.6314 31.2736 13.4807 31.6552 15.3527 31.6552C18.1919 31.6552 20.4854 30.908 22.2332 29.4138C23.952 27.9442 25.0408 25.8469 25.0305 22.8347L25.0305 21.2718C25.0305 18.1733 24.7292 15.8599 24.3551 14.2498C23.9669 12.5807 22.474 11.402 20.7563 11.402L14.9038 11.402C14.9038 11.402 19.1654 13.6982 20.8869 16.004C21.8523 17.2972 22.8715 19.3648 22.6166 22.1429C22.6166 22.1429 22.3612 25.0151 19.6135 26.2463"
        transform="translate(106.94898 1.7472159)"
        id="Fill-19"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
      <path
        d="M20.7957 0.177332C20.7896 0.17675 20.7835 0.175877 20.7777 0.175004C20.1727 0.0978881 19.4666 0.0586026 18.6603 0.0586026C17.2019 0.0586026 15.8135 0.299262 14.4954 0.780873C13.1765 1.26277 11.9666 1.92248 10.8658 2.76144C9.76427 3.60011 8.78706 4.60233 7.93421 5.76722C7.29523 6.63935 6.75679 7.56765 6.31802 8.5527C6.22475 8.76222 5.90976 8.69151 5.90976 8.46191L5.90976 3.41911C5.90976 1.52031 4.36476 -0.0150213 2.46874 0.000110903L0 0.0193171C0 0.0193171 1.74027 5.06823 2.26825 7.72451C2.79506 10.3808 3.30764 13.1698 3.30764 16.09C3.30764 19.0105 3.04351 21.7986 2.5164 24.4552C1.98842 27.1114 0.992902 31.6552 0.992902 31.6552L8.73272 31.6552C8.73272 31.6552 7.73633 27.1114 7.20893 24.4552C6.68124 21.7986 6.41798 19.0105 6.41798 16.09C6.41798 13.8338 6.9448 11.3114 8.32155 9.40389C9.71603 7.47104 11.313 6.11497 13.6731 5.74452C14.664 5.58912 15.8051 5.55071 16.7733 5.83997C17.8818 6.17142 18.8346 6.86197 19.8703 7.35551C19.8836 7.36192 19.897 7.36832 19.9104 7.37501C21.2191 8.02482 22.7551 7.1003 22.7551 5.63743L22.7551 2.43086C22.7551 1.29537 21.9203 0.323416 20.7957 0.177332"
        transform="translate(200.2449 1.7472159)"
        id="Fill-21"
        fill="#EF5430"
        fill-rule="evenodd"
        stroke="none"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: ["height", "width"],
};
</script>
<style lang="scss" scoped></style>

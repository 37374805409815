<template>
  <div>
    <v-card>
      <v-card-title> Individuals </v-card-title>
      <v-card-subtitle>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-subtitle>
      <workers-table
        v-if="!isLoading"
        :workers="workers"
        :headers="workerHeaders"
        :search="search"
      >
      </workers-table>
      <div class="center" v-else>
        <v-progress-circular
          indeterminate
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </div>
      <v-snackbar v-model="showSnackbar" absolute>
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import WorkersTable from "../../components/WorkersTable.vue";
import { mapGetters } from "vuex";
export default {
  title: 'Individuals',
  components: { WorkersTable },
  data() {
    return {
      search: "",
      isLoading: true,
      snackbarText: null,
      showSnackbar: false,
      workerHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "Type", value: "workerType.type" },
        { text: "Degree", value: "degree" },
        { text: "Speciality", value: "speciality.nameEn" },
        { text: "Contract Date", value: "contractDate" },
        { text: "Birthdate", value: "birthdate" },
        { text: "Gender", value: "gender" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Freelancer", value: "freelancer" },
        { text: "Active", value: "isActive" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("workers", ["workers"]),
  },
  created() {
                    console.log("load workers called");

    this.loadWorkers();
                console.log(this.workers);

  },
  methods: {
    async loadWorkers() {
      try {
        await this.$store.dispatch("workers/loadWorkers");

      } catch (error) {
        this.snackbarText = error;
        this.showSnackbar = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

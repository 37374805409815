import Vue from "vue";
import Vuex from "vuex";
import requestsModule from "./modules/requests/index.js";
import visitsModule from "./modules/visits/index.js";
import serviceProviderModule from "./modules/serviceprovider/index.js";
import authModule from "./modules/auth/index.js";
import addressModule from "./modules/address/index.js";
import workersModule from "./modules/workers/index.js";
import medicalServicesModule from "./modules/medicalservices/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    requests: requestsModule,
    visits: visitsModule,
    serviceProvider: serviceProviderModule,
    auth: authModule,
    address: addressModule,
    workers: workersModule,
    medicalServices: medicalServicesModule,
  },
});

import NetworkManager from "../../../network/network-manager.js";

export default {
  async loadRequestVisits(context, payload) {
    return await NetworkManager.get(context, `user-visit/request/${payload}`, "UserVisit", "setVisits");
  },
  async loadPaginatedVisits(context, payload) {
    return await NetworkManager.getPaginated(context, `user-visit/paginated`, "UserVisit", "setPaginatedVisits", payload);
  },
  async scheduleVisit(context, payload) {
    return await NetworkManager.post(context, `user-request/mvp/${payload.userRequest.id}/schedule`, payload, "UserVisit", "addVisit");
  },
  async updateVisit(context, payload) {
    const data = await NetworkManager.put(context, `user-visit/${payload.visit.id}`, payload.visit, "UserVisit");
    context.commit("updateVisit", { userVisit: data, index: payload.index });
    return data;
  },
  async cancelVisit(context, payload) {
    const data = await NetworkManager.put(context, `user-visit/${payload.visit.id}/cancel`, payload.visit, "UserVisit");
    context.commit("updateVisit", { userVisit: data, index: payload.index });
    return data;
  },
  async closeVisit(context, payload) {
    const data = await NetworkManager.put(context, `user-visit/${payload.visit.id}/close`, payload.visit, "UserVisit");
    context.commit("updateVisit", { userVisit: data, index: payload.index });
    return data;
  },
};

import ProviderType from "../models/provider-type";
import MedicalService from "../models/medical-service";
import ServiceProvider from "../models/service-provider.js";
import Worker from "../models/worker.js";
import WorkerType from "../models/worker-type";
import MedicalServiceDetail from "../models/medical-service-detail";
import GeographicalHierarchy from "../models/geographical-hierarchy";

export default class Parser {
  static parse(type, data) {
    switch (type) {
      case "ProviderType":
        return this.fromJson(ProviderType, data);
      case "MedicalService":
        return this.fromJson(MedicalService, data);
      case "ServiceProvider":
        return this.fromJson(ServiceProvider, data);
      case "Worker":
        return this.fromJson(Worker, data);
      case "WorkerType":
        return this.fromJson(WorkerType, data);
      case "MedicalServiceDetail":
        return this.fromJson(MedicalServiceDetail, data);
      case "GeographicalHierarchy":
        return this.fromJson(GeographicalHierarchy, data);
      default:
        return data;
    }
  }
  static fromJson(type, data) {
    try {
      if (data.constructor === Array) {
        const result = [];
        data.forEach((element) => {
          result.push(new type(element));
        });
        return result;
      } else return new type(data);
    } catch (error) {
      throw new Error("Error while parsing data");
    }
  }
}

import BaseModel from "./base-model";
export default class ContactPerson extends BaseModel {
    constructor (rawData = {}) {
        super(rawData);
        this.id = rawData.id;
        this.firstName = rawData.firstName;
        this.lastName = rawData.lastName;
        this.phoneNumber = rawData.phoneNumber;
        this.email = rawData.email;
        this.fullName = this.firstName + ' ' + this.lastName;  //For UI only
    }
}
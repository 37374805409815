<template>
  <div>
    <div class="logo-wraper">
      <router-link to="/requests">
        <cargiver-logo></cargiver-logo>
      </router-link>
    </div>
    <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ getName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ getEmail }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group v-model="selectedItem" color="secondary">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import CargiverLogo from "./CargiverLogo.vue";
import Vue from "vue";

export default {
  components: { CargiverLogo },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Patient Requests", icon: "mdi-account-multiple", to: "/requests" },
      { text: "Healthcare Providers Visits", icon: "mdi-star", to: "/visits" },
      { text: "Healthcare Instituations", icon: "mdi-star", to: "/serviceproviders" },
      { text: "Individuals", icon: "mdi-star", to: "/workers" },
    ],
  }),
  computed: {
    getEmail() {
      return Vue.$keycloak.idTokenParsed.email;
    },
    getName() {
      return Vue.$keycloak.idTokenParsed.name;
    },
  },
};
</script>
<style scoped>
.logo-wraper {
  padding: 6%;
  height: 64px;
}
</style>

import ServiceProvider from "./service-provider";
import UserRequest from "./user-request";
import Worker from "./worker";
import BaseModel from "./base-model";

export default class UserVisit extends BaseModel {
    constructor (rawData = {}) {
        super(rawData);
        this.id = rawData.id;
        this.visitDate = rawData.visitDate;
        this.fees = rawData.fees;
        this.comments = rawData.comments;
        this.serviceProvider = rawData.serviceProvider ? new ServiceProvider(rawData.serviceProvider) : null;
        this.worker = rawData.worker ? new Worker(rawData.worker) : null;
        this.userRequest = rawData.userRequest ? new UserRequest(rawData.userRequest) : null;
        this.medicalRecords = rawData.medicalRecords;
        this.status = rawData.status;
        this.isFreelance = rawData.isFreelance;
    }
}
export default {
  setWorkerTypes(state, payload) {
    state.workerTypes = payload;
  },
  setWorkers(state, payload) {
    state.workers = payload;
  },
  addWorker(state, payload) {
    state.workers.unshift(payload);
  },
  updateWorkers(state, payload) {
    state.workers.splice(payload.index, 1, payload.worker);
  },
};

import BaseModel from "./base-model";
export default class MedicalUser extends BaseModel {
    constructor (rawData = {}) {
        super(rawData);
        this.id = rawData.id;
        this.fullName = rawData.fullName;
        this.birthdate = rawData.birthdate;
        this.gender = rawData.gender;
        this.bloodType = rawData.bloodType;
        this.relationship = rawData.relationship;
        this.userId = rawData.userId;
        this.isSubProfile = rawData.isSubProfile;
    }
}
import Vue from "vue";
import VueRouter from "vue-router";
import Request from "../views/requests/RequestsList";
import ServiceProviders from "../views/serviceprovider/ServiceProvidersList";
import ServiceProvidersDetails from "../views/serviceprovider/ServiceProvidersDetails";
import WorkersList from "../views/workers/WorkersList";
import VisitsList from "../views/visits/VisitsList";
import RequestDetails from "../views/requests/RequestDetails";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "requests",
    component: Request,
  },
  {
    path: "/requests",
    name: "Request",
    component: Request,
  },
  {
    path: "/serviceproviders",
    name: "ServiceProviders",
    component: ServiceProviders,
  },
  {
    path: "/workers",
    name: "Workers",
    component: WorkersList,
  },
  {
    path: "/visits",
    name: "Visits",
    component: VisitsList,
  },
  {
    path: "/requests/:id",
    name: "RequestDetails",
    props: true,
    component: RequestDetails,
  },
  {
    path: "/serviceproviders/:id",
    name: "ServiceProvidersDetails",
    props: true,
    component: ServiceProvidersDetails,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

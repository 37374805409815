import { ContactPerson, Address, ProviderType, GeographicalHierarchy, MedicalServiceDetail } from "./index";
import Parser from "../network/parser.js";
import BaseModel from "./base-model";
export default class ServiceProvider extends BaseModel {
  constructor(rawData = {}) {
    super(rawData);
    this.id = rawData.id;
    this.name = rawData.name;
    this.address = rawData.address ? new Address(rawData.address) : null;
    this.contractDate = rawData.contractDate;
    this.providerType = rawData.providerType ? new ProviderType(rawData.providerType) : null;
    this.medicalServicesDetails = rawData.medicalServicesDetails ? Parser.fromJson(MedicalServiceDetail, rawData.medicalServicesDetails) : [];
    this.contactPerson = rawData.contactPerson ? new ContactPerson(rawData.contactPerson) : null;
    this.geographicalHierarchies = rawData.geographicalHierarchies ? Parser.fromJson(GeographicalHierarchy, rawData.geographicalHierarchies) : [];
  }
}

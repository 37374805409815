/*
export const KEYKLOACK_URL = "https://sso.caregivereg.com/auth/";
export const APIS_URL = "https://api.caregivereg.com/api";
*/

// export const KEYKLOACK_URL = "http://164.90.241.7/auth/";
// export const APIS_URL = "http://188.166.193.245/api";


export const KEYKLOACK_URL = "https://caregivereg.com/auth/";
export const APIS_URL = "https://caregivereg.com/api";


export const REQUESTS_PAGE_SIZE = 100000;
export const VISITS_PAGE_SIZE = 100000;

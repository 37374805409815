export default {
    setVisits(state, payload) {
      state.visits = payload;
    },
    setPaginatedVisits(state, payload) {
      state.visits = payload.results;
      state.totalNumberOfVisits = payload.totalNumberOfElements;
    },
    addVisit(state, payload) {
      state.visits.unshift(payload);
    },
    updateVisit(state, payload) {
      state.visits.splice(payload.index, 1, payload.userVisit);
    }
  };
  
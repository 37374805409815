import { MedicalServiceDetail, WorkerType, GeographicalHierarchy, ServiceProvider } from ".";
import Parser from "../network/parser.js";
import BaseModel from "./base-model";
export default class Worker extends BaseModel {
  constructor(rawData = {}) {
    super(rawData);
    this.id = rawData.id;
    this.firstName = rawData.firstName?rawData.firstName:null;
    this.secondName = rawData.secondName?rawData.secondName:null;
    this.lastName = rawData.lastName? rawData.lastName:null;
    this.degree = rawData.degree;
    this.speciality =rawData.speciality? new MedicalServiceDetail(rawData.speciality):null;
    this.contractDate = rawData.contractDate;
    this.birthdate = rawData.birthdate;
    this.gender = rawData.gender;
    this.phoneNumber = rawData.phoneNumber;
    this.email = rawData.email;
    this.freelancer = rawData.freelancer;
    this.workerType =rawData.workerType? new WorkerType(rawData.workerType):null;
    this.geographicalHierarchies = rawData.geographicalHierarchies ? Parser.fromJson(GeographicalHierarchy, rawData.geographicalHierarchies) : [];
    this.isActive = rawData.isActive;
    this.serviceProviders = rawData.serviceProviders ? Parser.fromJson(ServiceProvider, rawData.serviceProviders) : [];
    this.fullName = rawData.firstName? (rawData.firstName+ " " + (rawData.secondName ? rawData.secondName  : '') + " " + rawData.lastName):null;
  }
}

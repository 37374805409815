import BaseModel from "./base-model";
export default class TimeSlot extends BaseModel {
  constructor(rawData = {}) {
    super(rawData);
    this.id = rawData.id;
    this.slotDate = rawData.slotDate;
    this.timeFrom = rawData.timeFrom;
    this.timeTo = rawData.timeTo;
  }
}

import Vue from 'vue';

export default {
  login(context, payload) {
    context.commit("setUser", {
      username: payload.username,
      idToken: payload.idToken,
      accessToken: payload.accessToken
    });
  },
  logout(context) {
    //keycloak logout
    Vue.$keycloak.logout();

    context.commit("setUser", {
      username: null,
      idToken: null,
      accessToken: null,
    });
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" User Visits ")]),_c('v-card-subtitle',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.visits,"search":_vm.search,"loading":_vm.loading,"items-per-page":10},scopedSlots:_vm._u([{key:"item.isFreelance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isFreelance(item.isFreelance))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"subheaders--text"},[_vm._v("Manage Visits")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeSaveDialog(true)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canAddVisit)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Visit ")]):_vm._e()]}}]),model:{value:(_vm.showSaveDialog),callback:function ($$v) {_vm.showSaveDialog=$$v},expression:"showSaveDialog"}},[(_vm.showSaveDialog)?_c('NewVisit',{attrs:{"visit":_vm.visit,"editedIndex":_vm.editedIndex,"userRequest":_vm.chosenVisitRequest},on:{"close":_vm.closeSaveDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showCancelDialog),callback:function ($$v) {_vm.showCancelDialog=$$v},expression:"showCancelDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Are you sure you want to cancel this user visit?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closeCancelDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.confirmCancelVisit}},[_vm._v("Cancel Visit")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"510px"},model:{value:(_vm.showCloseDialog),callback:function ($$v) {_vm.showCloseDialog=$$v},expression:"showCloseDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Are you sure you want to complete this user visit?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closeCloseDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.confirmCloseVisit}},[_vm._v("Complete")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"secondary","disabled":!_vm.canEdit(item)},on:{"click":function($event){return _vm.editVisit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"secondary","disabled":!_vm.canCancelled(item)},on:{"click":function($event){return _vm.cancelVisit(item)}}},[_vm._v(" mdi-cancel ")]),_c('v-icon',{attrs:{"medium":"","color":"secondary","disabled":!_vm.canClosed(item)},on:{"click":function($event){return _vm.closeVisit(item)}}},[_vm._v(" mdi-check ")])]}}])}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
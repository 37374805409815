import BaseModel from "./base-model";
import { MedicalService } from ".";

export default class MedicalServiceDetail extends BaseModel {
    constructor (rawData = {}) {
        super(rawData);
        this.id = rawData.id;
        this.nameEn = rawData.nameEn;
        this.nameAr = rawData.nameAr;
        this.description = rawData.description;
        this.medicalService = rawData.medicalService ? new MedicalService(rawData.medicalService) : null;
    }
}
<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row><div class="text-subtitle-1">Instituion Details</div></v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-select
                :items="serviceProviderTypes"
                item-text="type"
                item-value="id"
                v-model="serviceProvider.providerType.id"
                :rules="required"
              >
                <template v-slot:label>
                  Instituion Type
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="serviceProvider.name" :rules="required">
                <template v-slot:label>
                  Instituion Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row><div class="text-subtitle-1">Address</div></v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="serviceProvider.address.streetName"
                maxlength="25"
                :rules="required"
              >
                <template v-slot:label>
                  Street
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="serviceProvider.address.buildingNumber"
                maxlength="6"
                :rules="[...required, ...numberRule]"
              >
                <template v-slot:label>
                  Building Number
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="serviceProvider.address.flatNumber"
                maxlength="6"
                :rules="numberRule"
              >
                <template v-slot:label>
                  Flat Number
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-select
                :items="areas"
                v-model="serviceProvider.address.geographicalHierarchy.id"
                item-text="name"
                item-value="id"
                :rules="required"
              >
                <template v-slot:label>
                  Area
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12">
              <v-text-field
                v-model="serviceProvider.address.fullAddress"
                label="Full Address"
                :rules="required"
              >
                <template v-slot:label>
                  Full Address
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row><div class="text-subtitle-1">Contact Person</div></v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="serviceProvider.contactPerson.firstName"
                :rules="required"
              >
                <template v-slot:label>
                  First Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="serviceProvider.contactPerson.lastName"
                :rules="required"
              >
                <template v-slot:label>
                  Last Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                type="number"
                v-model="serviceProvider.contactPerson.phoneNumber"
                :rules="required"
              >
                <template v-slot:label>
                  Phone Number
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                type="mail"
                v-model="serviceProvider.contactPerson.email"
                label="Email"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="red darken-1" text @click="cancel(true)" v-if="!isAdd">
        Cancel
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="save"
        v-if="!isAdd"
        :disabled="!valid"
      >
        Save
      </v-btn>
      <v-progress-circular
        v-else
        indeterminate
        color="deep-orange lighten-2"
      ></v-progress-circular>

      <v-snackbar v-model="showSnackbar" absolute>
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["serviceProvider", "editedIndex"],
  data() {
    return {
      valid: false,
      isAdd: false,
      snackbarText: null,
      showSnackbar: false,
      required: [(v) => !!v || "this field is required"],
      numberRule: [
        (v) => !v || !isNaN(parseInt(v)) || "This field must be a number",
      ],
      emailRules: [
        (v) =>
          !!v == false ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters("serviceProvider", ["serviceProviderTypes"]),
    ...mapGetters("address", ["areas"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Healthcare Institution" : "Edit Healthcare Institution";
    },
  },
  mounted() {
    this.loadServiceProviderTypes();
    this.loadAreas();
  },
  methods: {
    async dispatchAction(actionName, payload) {
      try {
        await this.$store.dispatch(actionName, payload);
      } catch (error) {
        this.snackbarText = error;
        this.showSnackbar = true;
      }
    },
    loadServiceProviderTypes() {
      this.dispatchAction("serviceProvider/loadServiceProviderTypes", null);
    },
    loadAreas() {
      this.dispatchAction("address/loadAreas", null);
    },
    async save() {
      this.isAdd = true;
      var api =
        this.editedIndex > -1
          ? "serviceProvider/updateServiceProvider"
          : "serviceProvider/addServiceProvider";

      await this.dispatchAction(api, this.serviceProvider);
      await this.dispatchAction("serviceProvider/loadServiceProviders");
      this.cancel(false);
      this.isAdd = false;
    },
    cancel(isCancelled) {
      this.$emit("close", isCancelled);
    },
  },
};
</script>

import { GeographicalHierarchy } from "./index";
import BaseModel from "./base-model";
export default class Address extends BaseModel {
  constructor(rawData = {}) {
    super(rawData);
    this.id = rawData.id;
    this.streetName = rawData.streetName;
    this.buildingNumber = rawData.buildingNumber;
    this.flatNumber = rawData.flatNumber;
    this.fullAddress = rawData.fullAddress;
    this.geographicalHierarchy = rawData.geographicalHierarchy ? new GeographicalHierarchy(rawData.geographicalHierarchy) : null;
    this.entityId = rawData.entityId;
    this.entityType = rawData.entityType;
  }
}

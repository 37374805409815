<template>
  <div>
    <service-provider-table
      v-if="!isLoading"
      :items="serviceProviders"
      :headers="headers"
      :search="search"
      title="Manage Healthcare Institutions"
    >
    </service-provider-table>
    <div class="center" v-else>
      <v-progress-circular
        indeterminate
        color="deep-orange lighten-2"
      ></v-progress-circular>
    </div>
    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ServiceProviderTable from "../../components/ServiceProviderTable.vue";
import { mapGetters } from "vuex";
export default {
  title: 'Healthcare Institutions',
  components: { ServiceProviderTable },
  data() {
    return {
      search: "",
      isLoading: true,
      snackbarText: null,
      showSnackbar: false,
      headers: [
        { text: "Id", value: "id" },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Type",
          align: "start",
          value: "providerType.type",
        },
        {
          text: "Address",
          align: "start",
          value: "address.fullAddress",
        },
        {
          text: "Contact Person",
          align: "start",
          value: "contactPerson.fullName",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("serviceProvider", [
      "serviceProviders",
      "hasServiceProviders",
    ]),
  },
  created() {
    this.loadServiceProviders();
  },
  methods: {
    async loadServiceProviders() {
      try {
        await this.$store.dispatch("serviceProvider/loadServiceProviders");
      } catch (error) {
        this.snackbarText = error;
        this.showSnackbar = true;
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

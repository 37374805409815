<template>
  <v-card>
    <v-card-title> User Visits </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="visits"
      :search="search"
      :loading="loading"
      :items-per-page="10"
    >
      <!--eslint-disable -->
      <template #item.isFreelance="{ item }">
        {{ isFreelance(item.isFreelance) }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="subheaders--text"
            >Manage Visits</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="showSaveDialog"
            persistent
            @keydown.esc="closeSaveDialog(true)"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-if="canAddVisit"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                New Visit
              </v-btn>
            </template>
            <NewVisit
              v-if="showSaveDialog"
              @close="closeSaveDialog"
              :visit="visit"
              :editedIndex="editedIndex"
              :userRequest="chosenVisitRequest"
            ></NewVisit>
          </v-dialog>
          <v-dialog v-model="showCancelDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h6"
                >Are you sure you want to cancel this user visit?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeCancelDialog"
                  >Cancel</v-btn
                >
                <v-btn color="primary darken-1" text @click="confirmCancelVisit"
                  >Cancel Visit</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showCloseDialog" max-width="510px">
            <v-card>
              <v-card-title class="text-h6"
                >Are you sure you want to complete this user
                visit?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeCloseDialog"
                  >Cancel</v-btn
                >
                <v-btn color="primary darken-1" text @click="confirmCloseVisit"
                  >Complete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--eslint-disable -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          medium
          color="secondary"
          class="mr-2"
          @click="editVisit(item)"
          :disabled="!canEdit(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          medium
          color="secondary"
          @click="cancelVisit(item)"
          :disabled="!canCancelled(item)"
        >
          mdi-cancel
        </v-icon>
        <v-icon
          medium
          color="secondary"
          @click="closeVisit(item)"
          :disabled="!canClosed(item)"
        >
          mdi-check
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar v-model="showSnackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import { ServiceProvider, UserVisit, PaginationRequest } from "../models";
import NewVisit from "./NewVisit.vue";
import { mapGetters } from "vuex";

export default {
  components: { NewVisit },
  props: ["headers", "visits", "userRequest"],
  data: () => ({
    options: {},
    loading: false,
    search: "",
    showSaveDialog: false,
    showCloseDialog: false,
    showCancelDialog: false,
    showSnackbar: false,
    snackbarText: "",
    editedIndex: -1,
    visit: null,
    chosenVisitRequest: null,
  }),
  created() {
    this.visit = this.createFreshVisitInstance();
    this.chosenVisitRequest = this.userRequest ? this.userRequest : null;
  },
  watch: {
    options: {
      handler() {
        this.getPaginatedVisits();
      },
      deep: true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Visit" : "Edit Visit";
    },
    canAddVisit() {
      return (
        this.userRequest &&
        (this.userRequest.status === "CONFIRMED" ||
          this.userRequest.status === "SCHEDULED")
      );
    },
    ...mapGetters("visits", ["getTotalNumberOfVisits"]),
  },
  methods: {
    async getPaginatedVisits() {
      const { page, itemsPerPage } = this.options;
      let paginationRequest = new PaginationRequest({
        pageNumber: page,
        pageSize: itemsPerPage == -1 ? 10000000 : itemsPerPage,
      });
      try {
        this.loading = true;
        await this.$store.dispatch(
          "visits/loadPaginatedVisits",
          paginationRequest
        );
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.loading = false;
      }
    },
    async editVisit(item) {
      this.editedIndex = this.visits.indexOf(item);
      this.visit = item;
      if (!this.chosenVisitRequest) {
        this.chosenVisitRequest = await this.$store.dispatch(
          "requests/loadUserRequestById",
          this.visit.userRequest.id
        );
      }
      this.showSaveDialog = true;
    },

    closeVisit(item) {
      this.editedIndex = this.visits.indexOf(item);
      this.visit = Object.assign({}, item);
      this.showCloseDialog = true;
    },

    cancelVisit(item) {
      this.editedIndex = this.visits.indexOf(item);
      this.visit = Object.assign({}, item);
      this.showCancelDialog = true;
    },

    async confirmCloseVisit() {
      try {
        await this.$store.dispatch("visits/closeVisit", {
          visit: this.visit,
          index: this.editedIndex,
        });

        this.snackbarText = "Visit is closed successfully";
        this.showSnackbar = true;
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeCloseDialog();
      }
    },

    async confirmCancelVisit() {
      try {
        await this.$store.dispatch("visits/cancelVisit", {
          visit: this.visit,
          index: this.editedIndex,
        });

        this.snackbarText = "Visit is cancelled successfully";
        this.showSnackbar = true;
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeCancelDialog();
      }
    },

    closeSaveDialog: function (isCancelled) {
      this.showSaveDialog = false;
      this.editedIndex = -1;
      this.chosenVisitRequest = this.userRequest ? this.userRequest : null;
      this.visit = Object.assign({}, this.createFreshVisitInstance());
      if (!isCancelled) {
        this.showSnackbar = true;
        this.snackbarText = "Operation Is Done Successfully";
        this.editedIndex = -1;
      }
    },

    closeCloseDialog() {
      this.showCloseDialog = false;
      this.visit = Object.assign({}, this.createFreshVisitInstance());
      this.editedIndex = -1;
    },

    closeCancelDialog() {
      this.showCancelDialog = false;
      this.visit = Object.assign({}, this.createFreshVisitInstance());
      this.editedIndex = -1;
    },

    createFreshVisitInstance() {
      return new UserVisit({
        serviceProvider: new ServiceProvider(),
        userRequest: this.userRequest,
        isFreelance: false,
      });
    },
    canEdit(item) {
      return item.status !== "CANCELLED" && item.status !== "DONE";
    },
    canCancelled(item) {
      return item.status !== "CANCELLED" && item.status !== "DONE";
    },
    canClosed(item) {
      return item.status !== "CANCELLED" && item.status !== "DONE";
    },
    isFreelance(isFreelance) {
      return isFreelance ? "Yes" : "No";
    },
  },
};
</script>

export default class DateFormatter {
  static getDate(value) {
    value = new Date(value);
    const dd = String(value.getDate()).padStart(2, "0");
    const mm = String(value.getMonth() + 1).padStart(2, "0");
    const yyyy = value.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }
  static getTime(value) {
    value = new Date(value);
    const hh = String(value.getHours()).padStart(2, "0");
    const MM = String(value.getMinutes()).padStart(2, "0");

    return hh + ":" + MM;
  }
  static getFormatedTime(value) {
    value = new Date(value);
      var hours = value.getHours() > 12 ? value.getHours() - 12 : value.getHours();
      var am_pm = value.getHours() >= 12 ? "PM" : "AM";
      hours = hours < 10 ? "0" + hours : hours;
      var minutes = value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();
      var seconds = value.getSeconds() < 10 ? "0" + value.getSeconds() : value.getSeconds();
      return hours + ":" + minutes + ":" + seconds + " " + am_pm;

  }
  static getDateTime(value) {
    var date=this.getDate(value);
    var time=this.getFormatedTime(value);

    return date +" "+time;
  }
}

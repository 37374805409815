<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row><div class="text-subtitle-1">Requester Details</div></v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="userRequest.firstName"
                :disabled="!isNew"
                :rules="required"
              >
                <template v-slot:label>
                  First Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="userRequest.secondName"
                label="Second Name"
                :disabled="!isNew"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="userRequest.lastName"
                :disabled="!isNew"
                :rules="required"
              >
                <template v-slot:label>
                  Last Name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="userRequest.email"
                :rules="emailRules"
                label="Email"
                maxlength="50"
                :disabled="!isNew"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="userRequest.phoneNumber"
                :rules="[...required, ...numberRule]"
                maxlength="20"
                :disabled="!isNew"
              >
                <template v-slot:label>
                  Phone Number
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row><div class="text-subtitle-1">Address</div></v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="userRequest.address.streetName"
                label="Street Name"
                maxlength="25"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="userRequest.address.buildingNumber"
                label="Building Number"
                maxlength="6"
                :rules="[...numberRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6" sm="6">
              <v-text-field
                v-model="userRequest.address.flatNumber"
                label="Flat Number"
                maxlength="6"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6" sm="6">
              <v-select
                :items="areas"
                v-model="userRequest.address.geographicalHierarchy.id"
                item-text="name"
                item-value="id"
                :rules="required"
              >
                <template v-slot:label>
                  Area
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="d-flex" cols="12">
              <v-text-field
                v-model="userRequest.address.fullAddress"
                :rules="required"
                maxlength="100"
              >
                <template v-slot:label>
                  Full Address
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row><h5>Request Details</h5></v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="userRequest.emergency"
                label="Emergency"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userRequest.description"
                label="Request description"
                maxlength="100"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userRequest.comments"
                label="Comments"
                maxlength="100"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6" md="6">
              <v-select
                :items="medicalServices"
                item-text="nameEn"
                item-value="id"
                v-model="userRequest.medicalService.id"
                :rules="required"
                v-on:change="medicalServiceChanged"
                dense
              >
                <template v-slot:label>
                  Medical Service
                  <span class="mandatory-field">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" md="6">
              <v-select
                v-if="userRequest.medicalServiceDetail"
                :items="medicalServicesDetails"
                item-text="nameEn"
                item-value="id"
                v-model="userRequest.medicalServiceDetail.id"
                label="Medical Service Detail"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel" v-if="!isSaving">
        Cancel
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="save"
        :disabled="!valid"
        v-if="!isSaving"
      >
        Save
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="confirm"
        :disabled="!valid || !canConfirm"
        v-if="!isSaving"
      >
        Save and Confirm
      </v-btn>
      <v-progress-circular
        v-else
        indeterminate
        color="deep-orange lighten-2"
      ></v-progress-circular>
    </v-card-actions>

    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

 <script>
import { mapGetters } from "vuex";
import { MedicalServiceDetail } from "./../models";

export default {
  props: ["userRequest", "editedIndex"],
  data() {
    return {
      snackbarText: "",
      showSnackbar: false,
      valid: false,
      isSaving: false,
      medicalServicesDetails: [],
      required: [(v) => !!v || "This field is required"],
      numberRule: [
        (v) => !v || !isNaN(parseInt(v)) || "This field must be a number",
      ],
      emailRules: [
        (v) =>
          !!v == false ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  async created() {
    await this.loadMedicalServices();
    await this.loadMedicalServicesDetails();
    await this.loadAreas();
  },
  computed: {
    ...mapGetters("medicalServices", ["medicalServices"]),
    ...mapGetters("address", ["areas"]),
    formTitle() {
      return this.isNew ? "New User Request" : "Edit User Request";
    },
    isNew() {
      return this.editedIndex === -1;
    },
    canConfirm() {
      return this.isNew || this.userRequest.status === "RECEIVED";
    },
  },
  methods: {
    async loadMedicalServices() {
      await this.$store.dispatch("medicalServices/loadMedicalServices");
    },
    async loadMedicalServicesDetails() {
      await this.$store.dispatch("medicalServices/loadMedicalServicesDetails");

      if (!this.isNew) {
        if (!this.userRequest.medicalServiceDetail) {
          this.userRequest.medicalServiceDetail = new MedicalServiceDetail();
        }
        this.getMedicalServicesDetails();
      }
    },
    async loadAreas() {
      await this.$store.dispatch("address/loadAreas");
    },
    async save() {
      try {
        this.isSaving = true;
        const chosenArea = this.areas.find(
          (area) =>
            area.id === this.userRequest.address.geographicalHierarchy.id
        );
        this.userRequest.address.geographicalHierarchy = chosenArea;

        if (this.isNew) {
          this.userRequest.addedByAdmin = true;
          await this.$store.dispatch(
            "requests/addUserRequest",
            this.userRequest
          );
        } else {
          await this.$store.dispatch("requests/updateUserRequest", {
            userRequest: this.userRequest,
            index: this.editedIndex,
          });
        }
        this.$emit("close", false);
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.isSaving = false;
      }
    },
    async confirm() {
      try {
        this.isSaving = true;
        const chosenArea = this.areas.find(
          (area) =>
            area.id === this.userRequest.address.geographicalHierarchy.id
        );
        this.userRequest.address.geographicalHierarchy = chosenArea;
        if (this.isNew) {
          this.userRequest.addedByAdmin = true;
          await this.$store.dispatch(
            "requests/createConfirmedUserRequest",
            this.userRequest
          );
        } else {
          await this.$store.dispatch("requests/confirmUserRequest", {
            userRequest: this.userRequest,
            index: this.editedIndex,
          });
        }
        this.$emit("close", false);
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.isSaving = false;
      }
    },
    cancel() {
      this.$emit("close", true);
    },
    medicalServiceChanged() {
      this.userRequest.medicalServiceDetail = new MedicalServiceDetail();
      this.getMedicalServicesDetails();
    },
    getMedicalServicesDetails() {
      this.medicalServicesDetails = this.$store.getters[
        "medicalServices/medicalServicesDetailsByServiceId"
      ](this.userRequest.medicalService.id);
    },
  },
};
</script>
<template>
  <div>
    <v-expansion-panels v-model="panel" multiple v-if="selectedServiceProvider">
      <v-expansion-panel class="top-grey-border">
        <v-expansion-panel-header><h2>Main Data</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="5">
                <v-row>
                  <v-col cols="12">
                    <div
                      class="subheaders--text text-subtitle-1 text--darken-2"
                    >
                      Healthcare Instituation Details
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="text-subtitle-2">Name</div>
                    <span>
                      {{ selectedServiceProvider.name }}
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <div class="text-subtitle-2">Type</div>
                    <span>
                      {{ selectedServiceProvider.providerType.type }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <div class="text-subtitle-2">Contract Date</div>
                    <span v-if="selectedServiceProvider.contractDate">
                      {{ selectedServiceProvider.contractDate }}
                    </span>
                    <span class="text-body1" v-else> - </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-divider class="mx-4" horizontal></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div
                      class="subheaders--text text-subtitle-1 text--darken-2"
                    >
                      Address Details
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="text-subtitle-2">Street Name</div>
                    <span>
                      {{ selectedServiceProvider.address.streetName }}
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <div class="text-subtitle-2">Building Number</div>
                    <span>
                      {{ selectedServiceProvider.address.buildingNumber }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="text-subtitle-2">Flat Number</div>
                    <span>
                      {{ selectedServiceProvider.address.flatNumber }}
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <div class="text-subtitle-2">Area</div>
                    <span>
                      {{
                        selectedServiceProvider.address.geographicalHierarchy
                          .name
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-2">Full Address</div>
                    <span>
                      {{ selectedServiceProvider.address.fullAddress }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-none d-md-flex justify-center" md="1">
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="12" md="5">
                <v-row>
                  <v-col cols="12">
                    <div
                      class="subheaders--text text-subtitle-1 text--darken-2"
                    >
                      Contact Person
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-2">Name</div>
                    <span>
                      {{ selectedServiceProvider.contactPerson.fullName }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="text-subtitle-2">Phone</div>
                    <span>
                      {{ selectedServiceProvider.contactPerson.phoneNumber }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="text-subtitle-2">Email</div>
                    <span>
                      {{ selectedServiceProvider.contactPerson.email }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="top-grey-border">
        <v-expansion-panel-header
          ><h2>Medical Services</h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-table
            :headers="msHeaders"
            :items="selectedServiceProvider.medicalServicesDetails"
            :sort-by="['medicalService.nameEn', 'nameEn']"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="subheaders--text"
                  >Manage Medical Services</v-toolbar-title
                >
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="msDialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New Medical Service
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ serviceFormTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form v-model="msFormValid" ref="msForm">
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-select
                                :items="medicalServices"
                                v-model="medicalService.id"
                                item-text="nameEn"
                                item-value="id"
                                label="Medical Service"
                                dense
                                :rules="required"
                                v-on:change="
                                  getMedicalServicesDetailsByMedicalService
                                "
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-select
                                :items="medicalServicesDetails"
                                v-model="chosenMedicalServiceDetailsIds"
                                item-text="nameEn"
                                item-value="id"
                                label="Speciality"
                                dense
                                multiple
                                chips
                                :rules="required"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="close"
                        v-if="!isAddService"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="primary darken-1"
                        text
                        @click="addMedicalServiceDetails"
                        v-if="!isAddService"
                        :disabled="!msFormValid"
                      >
                        Save
                      </v-btn>
                      <div class="center" v-else>
                        <v-progress-circular
                          indeterminate
                          color="deep-orange lighten-2"
                        ></v-progress-circular>
                      </div>
                    </v-card-actions>

                    <v-snackbar v-model="showMedicalServiceSnackbar" absolute>
                      {{ medicalServiceSnackbarText }}

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="pink"
                          text
                          v-bind="attrs"
                          @click="showMedicalServiceSnackbar = false"
                        >
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="msDialogDelete" max-width="620px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Are you sure you want to delete this medical
                      service?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="closeDelete()"
                        v-if="!isDeleteService"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!isDeleteService"
                        color="primary darken-1"
                        text
                        @click="deleteItemConfirm()"
                        >Delete</v-btn
                      >
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="deep-orange lighten-2"
                      ></v-progress-circular>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <!--eslint-disable -->
            <template v-slot:item.actions="{ item }">
              <v-icon medium color="secondary" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="top-grey-border">
        <v-expansion-panel-header><h2>Healtcare Provider Individuals</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <workers-table
            v-if="!isLoading"
            :workers="workers"
            :headers="workerHeaders"
            :serviceProvider="selectedServiceProvider"
          >
          </workers-table>
          <div class="center" v-else>
            <v-progress-circular
              indeterminate
              color="deep-orange lighten-2"
            ></v-progress-circular>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-circular
      class="center"
      v-else
      indeterminate
      color="deep-orange lighten-2"
    ></v-progress-circular>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WorkersTable from "../../components/WorkersTable.vue";
import { MedicalService, MedicalServiceDetail } from "../../models";

export default {
  title: "Healthcare Institution Info",
  components: { WorkersTable },
  props: ["id"],
  data() {
    return {
      isLoading: true,
      msFormValid: false,

      msDialog: false,
      msDialogDelete: false,
      workerDialog: false,
      workerDialogDelete: false,

      isDeleteService: false,
      isAddService: false,

      snackbar: false,
      snackbarText: ``,

      showMedicalServiceSnackbar: false,
      medicalServiceSnackbarText: "",

      msHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "nameEn",
        },
        { text: "Category", value: "medicalService.nameEn" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      workerHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "Type", value: "workerType.type" },
        { text: "Degree", value: "degree" },
        { text: "Speciality", value: "speciality.name" },
        { text: "Contract Date", value: "contractDate" },
        { text: "Birthdate", value: "birthdate" },
        { text: "Gender", value: "gender" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Freelancer", value: "freelancer" },
        { text: "Active", value: "isActive" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      medicalService: new MedicalService(),
      medicalServiceDetail: new MedicalServiceDetail(),
      medicalServicesDetails: [],
      chosenMedicalServiceDetailsIds: [],

      panel: [0, 1, 2],
      disabled: false,
      readonly: false,

      required: [(v) => !!v || "this field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  destroyed() {
    this.$store.commit("serviceProvider/setSelectedServiceProvider", null);
  },
  async created() {
    try {
      await this.loadServiceProvider();
      this.loadServiceProvderWorkers();
      this.loadMedicalServices();
      this.loadMedicalServicesDetails();
      this.isLoading = false;
    } catch (error) {
      this.snackbarText = error;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapGetters("serviceProvider", ["selectedServiceProvider"]),
    ...mapGetters("medicalServices", ["medicalServices"]),
    ...mapGetters("workers", ["workers"]),

    serviceFormTitle() {
      return this.editedIndex === -1
        ? "New Medical Service"
        : "Edit Medical Service";
    },
  },

  methods: {
    async loadServiceProvider() {
      await this.$store.dispatch(
        "serviceProvider/loadServiceProviderById",
        this.id
      );
    },
    async loadMedicalServices() {
      await this.$store.dispatch("medicalServices/loadMedicalServices");
    },
    async loadServiceProvderWorkers() {
      await this.$store.dispatch(
        "workers/loadServiceProviderWorkers",
        this.selectedServiceProvider.id
      );
    },
    async loadMedicalServicesDetails() {
      await this.$store.dispatch("medicalServices/loadMedicalServicesDetails");
    },

    deleteItem(item) {
      this.editedIndex =
        this.selectedServiceProvider.medicalServicesDetails.indexOf(item);
      this.medicalServiceDetail = Object.assign({}, item);
      this.msDialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteMedicalServiceDetail();
      this.closeDelete();
    },
    async deleteMedicalServiceDetail() {
      try {
        this.isDeleteService = true;
        let deletedMedicalService = {
          id: this.id,
          medicalServiceDetailId: this.medicalServiceDetail.id,
        };
        await this.$store.dispatch(
          "serviceProvider/removeMedicalServiceDetail",
          deletedMedicalService
        );

        this.selectedServiceProvider.medicalServicesDetails.splice(
          this.editedIndex,
          1
        );
        this.snackbarText = "Medical Service Deleted Successfully";
        this.snackbar = true;
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      } finally {
        this.isDeleteService = false;
      }
    },

    close() {
      this.msDialog = false;
      this.$refs.msForm.resetValidation();
      this.medicalService = new MedicalService();
      this.medicalServicesDetail = new MedicalServiceDetail();
      this.medicalServicesDetails = [];
      this.editedIndex = -1;
    },

    closeDelete() {
      this.msDialogDelete = false;
      this.medicalService = new MedicalService();
      this.medicalServicesDetail = new MedicalServiceDetail();
      this.editedIndex = -1;
    },

    async addMedicalServiceDetails() {
      try {
        this.isAddService = true;
        let addedMedicalServiceDetails = {
          id: this.id,
          medicalServiceDetailIdList: {
            ids: this.chosenMedicalServiceDetailsIds,
          },
        };
        await this.$store.dispatch(
          "serviceProvider/addMedicalServiceDetails",
          addedMedicalServiceDetails
        );
        this.loadServiceProvider();
        this.close();
        this.snackbarText = "Medical Services Are Added Successfully";
        this.snackbar = true;
        this.chosenMedicalServiceDetailsIds = [];
      } catch (error) {
        this.showMedicalServiceSnackbar = true;
        this.medicalServiceSnackbarText = error;
      } finally {
        this.isAddService = false;
      }
    },
    getMedicalServicesDetailsByMedicalService() {
      this.chosenMedicalServiceDetailsIds = [];
      this.medicalServicesDetails = this.$store.getters[
        "medicalServices/medicalServicesDetailsByServiceId"
      ](this.medicalService.id);
    },
  },
};
</script>

import NetworkManager from "../../../network/network-manager.js";

export default {
  async loadServiceProviders(context) {
    await NetworkManager.get(context, `service-provider/active/true`, "ServiceProvider", "setServiceProviders");
  },
  async loadServiceProviderById(context, payload) {
    await NetworkManager.get(context, `service-provider/${payload}`, "ServiceProvider", "setSelectedServiceProvider");
  },
  async loadServiceProviderTypes(context) {
    await NetworkManager.get(context, `provider-type`, "ProviderType", "setServiceProviderTypes");
  },
  async addServiceProvider(context, payload) {
    await NetworkManager.post(context, `service-provider`, payload, "ServiceProvider");
  },
  async addMedicalServiceDetail(context, payload) {
    await NetworkManager.post(
      context,
      `service-provider/${payload.id}/medical-service-detail/${payload.medicalServiceDetailId}`,
      payload,
      "ServiceProvider"
    );
  },
  async addMedicalServiceDetails(context, payload) {
    await NetworkManager.post(
      context,
      `service-provider/${payload.id}/medical-service-detail`,
      payload.medicalServiceDetailIdList,
      "ServiceProvider"
    );
  },
  async removeMedicalServiceDetail(context, payload) {
    await NetworkManager.delete(
      context,
      `service-provider/${payload.id}/medical-service-detail/${payload.medicalServiceDetailId}`,
      payload,
      "ServiceProvider"
    );
  },
  async updateServiceProvider(context, payload) {
    await NetworkManager.put(context, `service-provider/${payload.id}`, payload, "ServiceProvider");
  },
  async removeServiceProvider(context, payload) {
    await NetworkManager.put(context, `service-provider/update-active-status`, payload, "ServiceProvider");
  },
};

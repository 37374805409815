import BaseModel from "./base-model";
export default class MedicalService extends BaseModel {
    constructor (rawData = {}) {
        super(rawData);
        this.id = rawData.id;
        this.nameEn = rawData.nameEn;
        this.descEn= rawData.descEn;
        this.startingFrom = rawData.startingFrom;
        this.order = rawData.order;
    }
}
export default {
  setServiceProviders(state, payload) {
    state.serviceProviders = payload;
  },
  setServiceProviderTypes(state, payload) {
    state.serviceProviderTypes = payload;
  },
  setSelectedServiceProvider(state,payload){
    state.selectedServiceProvider=payload
  }

};

import Vue from "vue";
import Parser from "./parser.js";

export default class NetworkManager {
  static async getPaginated(context, apiName, modelName, commitMethod, paginationRequest) {
    return await Vue.prototype.$http
      .post(apiName, paginationRequest)
      .then((response) => {
        const data = Parser.parse(modelName, response.data.response.result);
        if (commitMethod) {
          context.commit(commitMethod, { results: data, totalNumberOfElements: response.data.response.totalNumberOfElements });
        }
        return data;
      })
      .catch(function(error) {
        if (error.response) {
          throw new Error(error.response.data.message || "something wrong happened");
        }
      });
  }
  static async get(context, apiName, modelName, commitMethod) {
    return await Vue.prototype.$http
      .get(apiName)
      .then((response) => {
        const data = Parser.parse(modelName, response.data.response);
        if (commitMethod) {
          context.commit(commitMethod, data);
        }
        return data;
      })
      .catch(function(error) {
        console.log("error",error);

        if (error.response) {
          throw new Error(error.response.data.message || "something wrong happened");
        }
      });
  }
  static async post(context, apiName, body, modelName, commitMethod) {
    return await Vue.prototype.$http
      .post(apiName, body)
      .then((response) => {
        const data = Parser.parse(modelName, response.data.response);
        if (commitMethod) {
          context.commit(commitMethod, data);
        }
        return data;
      })
      .catch(function(error) {
        if (error.response) {
          throw new Error(error.response.data.message || "something wrong happened");
        }
      });
  }
  static async delete(context, apiName, payload, modelName, commitMethod) {
    return await Vue.prototype.$http
      .delete(apiName, payload)
      .then((response) => {
        const data = Parser.parse(modelName, response.data.response);
        if (commitMethod) {
          context.commit(commitMethod, data);
        }
        return data;
      })
      .catch(function(error) {
        if (error.response) {
          throw new Error(error.response.data.message || "something wrong happened");
        }
      });
  }
  static async put(context, apiName, payload, modelName, commitMethod) {
    return await Vue.prototype.$http
      .put(apiName, payload)
      .then((response) => {
        const data = Parser.parse(modelName, response.data.response);
        if (commitMethod) {
          context.commit(commitMethod, data);
        }
        return data;
      })
      .catch(function(error) {
        if (error.response) {
          throw new Error(error.response.data.message || "something wrong happened");
        }
      });
  }
}

import Vue from "vue";
import Keycloak from "keycloak-js";
import { KEYKLOACK_URL } from "../constants";


let options = {
  url: `${KEYKLOACK_URL}`,
  realm: "care-giver",
  clientId: "care-giver-admin",
  checkLoginIframe: false
};

const _keycloak = Keycloak(options);

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak;
  },
};

Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;

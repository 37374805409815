import NetworkManager from "../../../network/network-manager.js";
export default {
  async loadWorkerTypes(context) {
    await NetworkManager.get(context, `worker-type`, "WorkerType", "setWorkerTypes");
  },
  async loadServiceProviderWorkers(context, payload) {
    await NetworkManager.get(context, `worker/service-provider/${payload}`, "Worker", "setWorkers");
  },
  async loadFreelanceWorkers(context) {
    await NetworkManager.get(context, `worker/freelancer/true`, "Worker", "setWorkers");
  },
  async loadWorkers(context) {
    await NetworkManager.get(context, `worker`, "Worker", "setWorkers");
  },
  async addWorker(context, payload) {
    await NetworkManager.post(context, `worker`, payload, "Worker", "addWorker");
  },
  async updateWorker(context, payload) {
    const data = await NetworkManager.put(context, `worker/${payload.worker.id}`, payload.worker, "Worker");
    context.commit("updateWorkers", { worker: data, index: payload.index });
    return data;
  },
  async deactivateWorker(context, payload) {
    const data = await NetworkManager.put(context, `worker/update-active-status`, payload.activeStatus, "Worker");
    context.commit("updateWorkers", { worker: data, index: payload.index });
    return data;
  },
  async unlinkWorkerFromServiceProvider(context, payload) {
    await NetworkManager.delete(context, `worker/${payload.id}/serviceProvider/${payload.serviceProviderId}`, null, "Worker");
  },
  async updateWorkerServiceProviders(context, payload) {
    const  data = await NetworkManager.post(context, `worker/${payload.worker.id}/update-service-providers`, payload.serviceProviderIdList, "Worker");
    context.commit("updateWorkers", { worker: data, index: payload.index });
    return data;
  },
};

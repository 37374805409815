<template>
  <v-card>
    <v-card-title> User Requests </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="requests"
      :loading="loading"
      :items-per-page="10"
    >
      <!--eslint-disable -->
      <template #item.request_date="{ item }">
        {{ getDateTime(item.requestDate) }}
      </template>
      <template #item.addedByAdmin="{ item }">
        {{ getAddedByAdminText(item) }}
      </template>
      <template #item.emergency="{ item }">
        {{ getEmergencyText(item) }}
      </template>
      <template v-slot:item.emergency="{ item }">
        <v-chip :color="getEmergencyColor(item)" dark>
          {{ getEmergencyText(item) }}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="subheaders--text"
            >Manage Requests</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            persistent
            @keydown.esc="closeSaveDialog(true)"
            v-model="showSaveDialog"
            max-width="550px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Request
              </v-btn>
            </template>
            <new-user-request
              v-if="showSaveDialog"
              @close="closeSaveDialog"
              :userRequest="userRequest"
              :editedIndex="editedIndex"
            ></new-user-request>
          </v-dialog>
          <v-dialog v-model="showConfirmDialog" max-width="550px">
            <v-card>
              <v-card-title class="text-h6"
                >Are you sure you want to confirm this user
                request?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeConfirmDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirmConfirmUserRequest"
                  >Confirm</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showCancelDialog" max-width="550px">
            <v-card>
              <v-card-title class="text-h6"
                >Are you sure you want to cancel this user
                request?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeCancelDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirmCancelUserRequest"
                  >Cancel Request</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showCloseDialog" max-width="550px">
            <v-card>
              <v-card-title class="text-h6"
                >Are you sure you want to complete this user
                request?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeCloseDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirmCloseUserRequest"
                  >Complete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--eslint-disable -->
      <template v-slot:item.actions="{ item }" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link class="router-link" :to="/requests/ + item.id">
              <v-icon
                color="secondary"
                medium
                class="mr-2"
                @click="cancelUserRequest(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </router-link>
          </template>
          <span>Edit Visits</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="confirmUserRequest(item)"
              :disabled="!canConfirm(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-check
            </v-icon>
          </template>
          <span>Confirm Request</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="closeUserRequest(item)"
              :disabled="!canClosed(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-check-circle-outline
            </v-icon>
          </template>
          <span>Complete Request</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="cancelUserRequest(item)"
              :disabled="!canCancelled(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-close-circle-outline
            </v-icon>
          </template>
          <span>Cancel Request</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              medium
              class="mr-2"
              @click="editUserRequest(item)"
              :disabled="!canEdit(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Request Info</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-snackbar v-model="showSnackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<style lang="scss">
.router-link {
  text-decoration: none;
  color: inherit;
}

.emergency {
  background-color: #ffbec1;
}
</style>

<script>
import {
  Address,
  GeographicalHierarchy,
  MedicalService,
  MedicalServiceDetail,
  MedicalUser,
  PaginationRequest,
  UserRequest,
} from "../models";
import NewUserRequest from "./NewUserRequest.vue";
import DateFormatter from "../utilities/date-formatter";
import { mapGetters } from "vuex";

export default {
  components: { NewUserRequest },
  props: ["headers", "requests"],
  data: () => ({
    options: {},
    loading: false,
    search: "",
    showSaveDialog: false,
    showCancelDialog: false,
    showCloseDialog: false,
    showConfirmDialog: false,
    showSnackbar: false,
    snackbarText: "",
    editedIndex: -1,
    userRequest: null,
  }),
  created() {
    this.userRequest = this.createFreshUserRequestInstance();
  },
  watch: {
    options: {
      handler() {
        this.getPaginatedUserRequests();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("requests", ["getTotalNumberOfRequests"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Request" : "Edit Request";
    },
  },
  methods: {
    async getPaginatedUserRequests() {
      const { page, itemsPerPage } = this.options;
      let paginationRequest = new PaginationRequest({
        pageNumber: page,
        pageSize: itemsPerPage == -1 ? 10000000 : itemsPerPage,
      });
      try {
        this.loading = true;
        await this.$store.dispatch(
          "requests/loadUserRequests",
          paginationRequest
        );
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.loading = false;
      }
    },
    editUserRequest(item) {
      this.selectUserRequest(item);
      this.showSaveDialog = true;
    },

    cancelUserRequest(item) {
      this.selectUserRequest(item);
      this.showCancelDialog = true;
    },

    confirmUserRequest(item) {
      this.selectUserRequest(item);
      this.showConfirmDialog = true;
    },

    closeUserRequest(item) {
      this.selectUserRequest(item);
      this.showCloseDialog = true;
    },

    selectUserRequest(item) {
      this.editedIndex = this.requests.indexOf(item);
      this.userRequest = Object.assign({}, item);
    },

    async confirmCancelUserRequest() {
      try {
        await this.$store.dispatch("requests/cancelUserRequest", {
          userRequest: this.userRequest,
          index: this.editedIndex,
        });
        this.showSnackbar = true;
        this.snackbarText = "User request is cancelled successfully";
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeCancelDialog();
      }
    },

    async confirmConfirmUserRequest() {
      try {
        await this.$store.dispatch("requests/confirmUserRequest", {
          userRequest: this.userRequest,
          index: this.editedIndex,
        });
        this.snackbarText = "User request is confirmed successfully";
        this.showSnackbar = true;
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeConfirmDialog();
      }
    },

    async confirmCloseUserRequest() {
      try {
        await this.$store.dispatch("requests/closeUserRequest", {
          userRequest: this.userRequest,
          index: this.editedIndex,
        });

        this.snackbarText = "User request is completed successfully";
        this.showSnackbar = true;
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeCloseDialog();
      }
    },

    closeSaveDialog: function (isCancelled) {
      this.showSaveDialog = false;
      this.editedIndex = -1;
      this.userRequest = this.createFreshUserRequestInstance();
      if (!isCancelled) {
        this.showSnackbar = true;
        this.snackbarText = "Operation Is Done Successfully";
      }
    },

    closeCancelDialog() {
      this.showCancelDialog = false;
      this.userRequest = this.createFreshUserRequestInstance();
      this.editedIndex = -1;
    },

    closeConfirmDialog() {
      this.showConfirmDialog = false;
      this.userRequest = this.createFreshUserRequestInstance();
      this.editedIndex = -1;
    },

    closeCloseDialog() {
      this.showCloseDialog = false;
      this.userRequest = this.createFreshUserRequestInstance();
      this.editedIndex = -1;
    },

    createFreshUserRequestInstance() {
      return new UserRequest({
        medicalService: new MedicalService(),
        medicalServiceDetail: new MedicalServiceDetail(),
        address: new Address({
          geographicalHierarchy: new GeographicalHierarchy(),
        }),
        requester: new MedicalUser(),
      });
    },
    canEdit(item) {
      return item.status !== "CANCELLED" && item.status !== "DONE";
    },
    canCancelled(item) {
      return item.status !== "CANCELLED" && item.status !== "DONE";
    },
    canClosed(item) {
      return item.status !== "CANCELLED" && item.status !== "DONE";
    },
    canConfirm(item) {
      return item.status === "RECEIVED";
    },
    getDate(item) {
      return DateFormatter.getDate(item);
    },
     getDateTime(item) {
      return DateFormatter.getDateTime(item);
    },
    
    getAddedByAdminText(item) {
      return item.addedByAdmin ? "Yes" : "No";
    },
    getEmergencyText(item) {
      return item.emergency ? "Yes" : "No";
    },
    getEmergencyColor(item) {
      return item.emergency ? "#B71C1C" : "primary";
    },
  },
};
</script>

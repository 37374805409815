import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const theme = {
  primary: "#62b300",
  secondary: "#ef5430",
  accent: "#00FF00",
  error: "#f44336",
  warning: "#e91e63",
  info: "#4caf50",
  success: "#8bc34a",
  background: "#fff",
  subheaders: "#8e8c8c",
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
